@import 'assets/scss/variables';

.user-menu {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  &__img-wrap {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    overflow: hidden;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  &__name {
    color: #808080;	
    font-family: Roboto;
    font-size: 14px;
  }

  &__clock {
    padding: 0;
    font-size: 12px;
    cursor: pointer;
    color: $color-bondi-blue;
    text-transform: uppercase;
    text-align: left;
  }

  &__drop-menu-icon {
    width: 13px;
    height: 13px;
    margin-left: 15px;
    color: $color-bondi-blue;

    &--rotated {
      transform: rotate(180deg);
    }
  }

  .popup-menu {
    min-width: 240px;
    border-bottom: 2px solid $color-bondi-blue;
  }
}
