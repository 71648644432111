@import 'assets/scss/variables';

.mail-composer {
  position: relative;
  width: 100%;

  &--global-pending {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      background-color: transparent;
      cursor: wait;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-bottom: 15px;
  }

  &__field-wrap {
    position: relative;
  }

  &__form-group {
    position: relative;
    display: flex;
    margin-top: 17px;

    &--email {
      margin-top: 0;
      align-items: flex-end;
    }

    &--message {
      margin-top: 15px;
      height: 100%;

      .form-textarea {
        &__error {
          bottom: -14px;
        }
      }
    }

    .form-text-field {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  &__label {
    display: inline-flex;
    width: fit-content;
    align-items: center;
    height: 26px;
    color: #000001;
    font-family: Roboto;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
  }

  &__input-wrap {
    position: relative;
    width: 100%;
  }

  &__input,
  &__textarea  {
    width: 100%;
    height: 100%;
    outline: none;
    border-radius: 6px;
    color: #404040;
    background-color: #fff;
  }

  &__input {
    padding: 8px;

    &-placeholder {
      font-family: Roboto;
    }

    &--subject {
      height: 40px;
    }
  }

  &__textarea {
    resize: none;
    padding: 8px;
    border: 1px solid $color-silver;
  }

  &__actions-menu {
    margin-left: auto;
    display: flex;
  }

  &__action-btn {
    width: 36px;
    height: 36px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-size: 20px;
    color: $color-tundora;

    &:disabled {
      cursor: default;
    }
  }

  &__action-btn-icon {
    opacity: .6;
    transition: opacity 200ms;

    .mail-composer__action-btn:hover & {
      opacity: 1;
    }

    .mail-composer__action-btn:disabled & {
      opacity: .3;
    }
  }
}

.mail-composer-attachments {
  display: flex;
  align-items: flex-start;
  margin-top: 17px;
  margin-left: 15px;
  max-height: 100%;

  &__add-btn {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 20px;
    min-width: 120px;
    min-height: 32px;
    margin-right: 12px;
    background-color: $color-mystic;
    border-radius: 6px;

    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.875px;
    color: #000001;
    font-family: Roboto;

    .mail-composer--some-pending & {
      opacity: .5;
      cursor: default;
      color: #808080FF;
    }
  }

  &__add-btn-icon {
    margin-left: 10px;
    height: 15px;
    width: 15px;
    color: $color-bondi-blue;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -2px;
    padding: 0;
    list-style: none;
    height: 100%;
  }
  
  &__list-item {
    margin: 2px;
    height: 100%;
  }
}
