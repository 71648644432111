@import 'assets/scss/variables';

.system-msg {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  
  &__booking-pending {
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-right: 20px;
    padding: 3.5px 8px 3.5px 11px;
    border-radius: 6px;
    background-color: #fff;
    color: #000;
    font-size: 11px;

    &--with-attachment {
      border-radius: 6px 6px 0 0;
    }
  }

  &__attachment {
    width: 100%;
  }

  &__timeago {
    margin-right: 2px;
  }

  &__message {
    margin-right: 20px;
  }
  &__icon {
    width: 14px;
    height: 14px;
    margin-left: 8px;

    &--sms {
      color: $color-bondi-blue;
    }
    &--mail,
    &--out-call,
    &--telegram {
      color: $color-azure-radiance;
    }
    &--transfer-session {
      color: $color-ripe-lemon;
    }
    &--start-session {
      color: $color-spring-green;
    }
    &--inc-call {
      color: $color-apple;
    }
    &--booking {
      color: $color-jade;
    }
    &--close-session,
    &--delete-session,
    &--booking-removed {
      color: $color-froly;
    }
  }
}