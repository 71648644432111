@import "assets/scss/variables.scss";

.chat-header {
  padding: 5px 10px 0;
  // background-color: #fff;
  background-color: #E6EBF1;
  z-index: 7;

  &--with-agent {
    background-color: rgba(253, 5, 232, 0.15);

    .chat-header__name {
      color: rgb(151, 151, 151);
    }
  }

  &:hover .booked-dropdown {
    display: flex;
    align-items: center;
    padding: 0 7px;
    opacity: 1;
    height: 30px;
    box-shadow: -2px 2px 4px -1px rgba(0, 0, 0, 0.2);
  }

  &::after {
    box-sizing: border-box;
    display: inline-block;
    width: 7px;
    height: 7px;
    position: absolute;
    border-radius: 50%;
    top: 3px;
    left: 8px;
    transition: all 0.2s ease-in;
  }

  &--available::after {
    content: "";
    background-color: rgb(1, 223, 133);
  }

  &--off::after {
    content: "";
    background-color: #f8737f;
  }

  &__header {
    display: flex;

    .name__copy {
      &--btn {
        padding: 0;
      }
    }
  }
  &__ava-wrap {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
  }
  &__ava {
    border-radius: 50%;
  }
  &__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 7px;
    min-width: 0;
    font-size: 12px;

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
    }

    &-bottom {
      display: flex;
      align-items: center;
      margin-top: 4px;
    }
  }
  &__contact-details {
    display: flex;
    align-items: center;
    min-width: 0;
  }
  &__ex {
    margin-right: 6px;
    line-height: 1;
    color: #f27e3f;
    font-weight: bold;
  }

  &__name {
    overflow: hidden;
    margin-left: 6px;
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    font-size: 12px;
    cursor: pointer;
  }
  &__details {
    margin-right: 10px;
  }
  &__rating {
    margin-right: 5px;
    color: $color-azure-radiance;
    white-space: nowrap;
  }
  &__reviews {
    margin-right: 5px;
    padding: 0;
    color: $color-azure-radiance;
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;
  }

  &__action-buttons {
    position: relative;
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-left: 10px;
    margin-right: 18px;
    padding-top: 2px;
  }

  &__btn {
    display: flex;
    margin: 0 4px;
    padding: 0;
    color: $color-gray;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &--pin {
      margin: 0 2px;
    }
  }

  &__icon {
    width: 13px;
    height: 13px;
  }

  &__btn {
    &--info {
      position: absolute;
      right: 0px;
      top: 4px;
      padding: 3px 10px;
      cursor: pointer;
      border: none;
      outline: none;
      background: transparent;
    }

    &--agent {
      color: #0092f2;
      display: flex;
      align-items: center;
      text-decoration: underline;
      text-align: left;

      &:hover {
        color: #000;
        text-decoration: none;
      }
      &:hover svg {
        fill: #000;
      }
      svg {
        margin-right: 10px;
      }
    }
  }
  &__available {
    padding: 0 3px;
  }
  &__text-line {
    margin: 0;
  }
  &__text {
    white-space: pre-line;
    font-size: 13px;
    border: none;
    outline: none;
    box-shadow: none;
    resize: none;
    text-align: center;
    width: 100%;
    padding: 0;

    &--link {
      color: blue;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__actual-bookings {
    font-size: 15px;
    padding: 0px 3px !important;
  }
  &__tags {
    padding: 5px 0;
  }
  &__text-line {
    display: flex;
    align-items: center;
    text-align: left;
    margin: 2px 0;
  }

  &__btn--share {
    position: absolute;
    background-color: #fff;
    padding: 2px 20px;
    left: calc(50% - 28px);
    cursor: pointer;
    border: none;
    outline: none;
    top: 8px;

    &:active {
      top: 6px;
    }
  }
  &__footer {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    position: relative;
  }
  &__booked-dropdown {
    position: absolute;
    bottom: -10px;
    right: -10px;
    width: 80px;
  }
  &__img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  &--hidden {
    height: 74px;
    background-color: #fff;
  }
  &--hidden > &__header,
  &--hidden > &__footer {
    display: none;
  }
  &__block-info {
    border: 1px solid rgba(27, 31, 35, 0.15);
    position: absolute;
    background: #fff;
    border-radius: 5px;
    font-size: 14px;
    padding: 5px;
    z-index: 1;
    box-shadow: 0 3px 12px rgba(27, 31, 35, 0.15);
  }

  &__exclusive {
    // margin-left: 4px;
    font-size: 13px;
    color: #f2cc0c;
  }

  &__contact-tels,
  &__contact-emails {
    display: flex;
    justify-content: center;
    width: 50%;
  }

  &__select-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;

    .react-select {
      &__option {
        &:last-child {
          color: $color-azure-radiance;
          border-radius: 0 0 6px 6px;
          background-color: inherit !important;
        }
      }
      &__control {
        padding-left: 28px;
      }
    }
  }

  &__select-btn {
    position: absolute;
    top: 50%;
    left: 12px;
    z-index: 1;
    transform: translateY(-50%);
    display: flex;
    padding: 0;

    &--email {
      cursor: default;
    }
  }

  &__select-icon {
    color: $color-gray;
    width: 12px;
    height: 12px; 
  }

  &__add-new-btn {
    padding: 0;
    color: $color-bondi-blue;
  }

  &__action-menu {
    margin: -5px -10px 0 0;
  }

  &__rates {
    $indent: 5px;

    position: absolute;
    z-index: 100;
    top: calc(100% + #{$indent});
    right: -30px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 100%;
      width: 100%;
      height: #{$indent};
      background-color: transparent;
    }
  }
}

.chat-header__contact-tels {
  .icon-menu {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 4px;

    width: 26px;

    border-radius: 6px 6px 0 0;
    color: #808080;
  
    &.menu-open {
      color: #00a6ad;
      background-color: #fff;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .icon-menu-popup {
    min-width: 240px;
    border-radius: 0 0 6px 6px;
  }

  .popup-menu {
    overflow: hidden;
    border-radius: 0 0 6px 6px;
  }

  .popup-menu__item {
    &:first-child, &:last-child {
      border-radius: 0;
    }
  }
}

.attachment-contact__btn + .call-btn__dropdown {
  top: 75%;
}

.contact-bookings {
  &__open {
    position: absolute;
    right: 0px;
    top: 5px;
    cursor: pointer;
    font-size: 17px;
  }
}

.textDimensionCalculation {
    position: absolute;
    visibility: hidden;
    height: auto;
    width: auto;
    white-space: nowrap;
}
