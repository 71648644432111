.sales-page {
  height: calc(100vh - 62px);

  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 280px 615px minmax(760px, 1fr);
  grid-template-areas: "header header header"
                       "sidebar compare gallery";

  &--pending {
    grid-template: 1fr 66px / auto;
    justify-items: center;
    align-items: center;
  }
}

.sales-sidebar {
  grid-area: sidebar;
  background-color: bisque;     // temp
}

.sales-compare {
  position: relative;
  grid-area: compare;

  &::after {
    content: "";
    position: absolute;
    top: 15px;
    bottom: 15px;
    right: 0;
    transform: translateX(50%);
    width: 8px;

    background-image: url("/assets/images/separator-vertical.svg");
    background-size: contain;
  }
}
