@import 'assets/scss/variables';

.dialpad{
  display: flex;
  flex-direction: column;
  background-color: $color-mystic;
  z-index: 11;
  box-shadow: 0 16px 16px 0 rgba(0,0,0,0.08);

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 15px;
    box-shadow: 0 16px 16px 0 rgba(0,0,0,0.08);
    z-index: 1;
  }
  &__input {
    font-size: 14px;
    line-height: 19px;
    width: 240px;
    height: 40px;
    padding: 10px;
    border: none;
    border-radius: 6px;
    outline: none;
    border: solid 1px $color-white;
    letter-spacing: 0.88px;
    margin-right: 17px;
    &:focus {
      border: solid 1px $color-bondi-blue;
    }
  }
  &__btn-remove-all {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: absolute;
    cursor: pointer;
    padding: 15px;
    top: 14px;
    right: 310px;
    visibility: hidden;
    &--show {
      visibility: visible;
    }
  }
  &__icon-remove-all {
    fill: $color-gray;
    width: 12px;
    height: 12px;
  }
  &__btn-plus {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 50%;
    opacity: 1;
    margin-right: 18px;
    &:disabled {
      opacity: 0.3;
    }
  }
  &__icon-plus {
    width: 20px;
    height: 20px;
    fill: $color-bondi-blue;
  }
  &__tab {
    padding: 7px 9px;
    background-color: inherit;
    border-radius: 6px;
    border: solid 1px $color-white;
    color: $color-black;
    margin-right: 15px;
    &--active {
      background-color: $color-white;
      color: $color-bondi-blue;
    }
    &--not-used {
      opacity: 0.3;
    }
  }
  &__content {
    display: flex;
    background-color: $color-mystic;
    box-shadow: 0 16px 16px 0 rgba(0,0,0,0.08);
    border-bottom: solid 1px $color-bondi-blue;
  }
  &__btns{
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    padding: 14px 15px 24px 15px;
    width: 270px;
    background-color: $color-white;
  }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-text-primary-darkest;
    background-color: $color-bermuda;
    width: 64px;
    height: 64px;
    margin: 8px;
    font-size: 16px;
    line-height: 24px;
    border-radius: 5px;
    &:focus {
      background-color: $color-bondi-blue;
    }
  }
  &__icon-phone {
    width: 30px;
    height: 30px;
    outline: none;
    fill: $color-white;
  }
  &__icon-arrow {
    transform: rotate(-90deg);
    fill: $color-froly;
  }
  &__list-wrapper {
    width: 300px;
    padding: 15px 2px 15px 15px;
    &--search {
      background-color: $color-bermuda-opacity;
    }
  }
  &__tabs-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}


.dialpad-list {
  &__list {
    padding-right: 13px;
    text-align: start;
  }
  &__list-container {
    height: 100%;
  }
  &__load-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  &__item {
    cursor: pointer;
    margin-bottom: 6px;
    padding: 4px 10px;
    background-color: $color-white;
    border-radius: 6px;
    
    &--resent-calls {
      display: flex;
      border: solid 0.5px $color-border-primary;
      margin-bottom: 3px;
      padding: 5px;
    }
    &--search {
      display: flex;
      border: solid 0.5px $color-border-primary;
      margin-bottom: 3px;
      padding: 5px;
    }
  }
  &__text-box {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__icon-telegram {
    position: absolute;
    right: 0;
    bottom: -3px;
    width: 16px;
    height: 16px;
    border: 1px solid $color-white;
    border-radius: 50%;
    background: $color-white;
    color: $color-azure-radiance;
    overflow: hidden;
  }
  &__date-box {
    display: flex;
    padding: 3px 2px;
    color: #4A4A4A;
    font-size: 10px;
    line-height: 1.3;
  }
  &__icon-incoming {
    margin-right: 7px;
    width: 12px;
    height: 12px;
    fill: $color-gray;
  }
  &__info {
    display: flex;
    justify-content: space-between;
  }
  &__contact-name {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    margin-bottom: 3px;

    &--client {
      color: $color-dodger-blue;
    }
    &--girl {
      color: $color-azure-radiance;
    }
    &--agent {
      color: $color-seance;
    }
    &--recent {
      color: $color-dodger-blue;
      font-size: 12px;
    }
  }
  &__operator-name {
    text-transform: capitalize;
    max-width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 15px;

    &--online {
      color: $color-azure-radiance;
    }
    &--bisy {
      color: $color-green-haze;
    }
    &--away {
      color: $color-selective-yellow;
    }
    &--offline {
      color: $color-red-orange;
    }
  }
  &__avatar-box {
    position: relative;
    margin-right: 5px;
    min-width: 32px;
    height: 32px;
  }
  &__avatar {
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
  &__tel {
    font-size: 12px;
    color: $color-gray;
  }
  &__no-items {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}