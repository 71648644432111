.adr-book-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 62px);

  &__main {
    display: flex;
    flex-grow: 1;
    padding: 16px 30px 5px 20px;
  }
}