@import 'assets/scss/variables';

.profile-compare-zone {
  gap: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 15px;
  padding: 0 12px;
  height: calc(100% - 10px);
  border: 2px dotted transparent;

  &--drag-enter-left,
  &--drag-enter-right {
    border: 2px dotted $color-gray;

    & * {
      pointer-events: none;
    }
  }
  &--drag-enter-left {
    & > .profile-card:nth-child(1) {
      opacity: 0.5;
    }
  }
  &--drag-enter-right {
    & > .profile-card:nth-child(2) {
      opacity: 0.5;
    }
  }
  &--drag-enter-already-exist {
    border: 2px dotted $color-pink;
  }
}

@media (max-width: 1640px) {
  .profile-compare-zone {
    flex-grow: 1;
  }
}
@media (min-width: 1640px) {
  .profile-compare-zone {
    min-width: 574px;
  }
}
