@import "assets/scss/variables.scss";

.ii-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  background: transparent;
  font-size: 14px;
  position: absolute;
  right: 0;
  top:0;

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2px;
    width: 22px;
    height: 22px;
    border-radius: 6px 0px 6px 6px;
    background-color: $color-bondi-blue;
    cursor: pointer;
    transition: background-color linear .1s;

    &:hover {
      background-color: $color-bermuda;

      .ii-menu__tooltip {
        visibility: visible;
      }
    }

    &--danger {
      background-color: $color-froly;

      &:hover {
        background-color: lighten($color-froly, 10);
      }
    }
  
    &--limit-pin-msg {
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
    }
  }
  
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 0;
    font-size: 12px;
  }

  &__tooltip {
    visibility: hidden;
    padding: 3px 10px;
    font-size: 12px;
    background-color: $color-black-squeeze-light;
    color: #000;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    bottom: 31px;
    right: -6px;
    z-index: 1;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    letter-spacing: 1px;

    &::after {
      content: "";
      position: absolute;
      bottom: -12px;
      right: 10px;
      display: inline-block;
      border: 7px solid transparent;
      border-top-color: $color-black-squeeze-light;
    }
  }

  &__status {
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    padding: 8px 0;
    border: 1px solid red;
    background: lightgreen;
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    border-radius: 5px;
    font-size: 12px;
    z-index: 2;
    // max-height: 35px;
    width: 80px;
  }

  &__icon {
    width: 14px;
    height: 14px;
    color: $color-white;

    &--limit-pin-msg {
      transform: rotate(45deg);
      color: $color-bondi-blue;
    }
  }
}

.ii-sub-menu {
  position: absolute;
  width: 130px;
  right: 0;
  top: 27px;
  z-index: 10;

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    height: 5px;
    width: 100%;
    background-color: transparent;
  }

  // &--glossary {
  //   color: $color-bondi-blue;
  //   width: 230px;
  //   padding: 5px 0 10px 0;

  //   .ii-sub-menu__item {
  //     border: none;
  //     position: relative;
  //     padding: 12px 12px 12px 20px;

  //     &:hover{
  //       background-color: #f6f6f6;
  //       color: $color-bondi-blue;
  //     }

  //     &--disabled {
  //       background: #c591913b;
  //       cursor: not-allowed;

  //       &:hover {
  //         background: #c591913b;
  //       }
  //     }

  //     &::before {
  //       content: "";
  //       background: #BFBFBF;
  //       position: absolute;
  //       bottom: 0;
  //       height: 1px;
  //       width: 75%;
  //     }

  //     &:nth-child(1) {
  //       &::after {
  //         content: '';
  //         position: absolute;
  //         width: 10px;
  //         height: 10px;
  //         border-radius: 50%;
  //         background-color: #01df85;
  //         left: 10px;
  //       }
  //     }

  //     &:nth-child(2) {
  //       &::after {
  //         content: '';
  //         position: absolute;
  //         width: 10px;
  //         height: 10px;
  //         border-radius: 50%;
  //         background-color: #f8737f;
  //         left: 10px;
  //       }
  //     }
  //   }
  // }
  
}


//  this part for last message in chat 
//  to fit in viewport of timeline in all possible cases. 

//   .timeline__group:last-of-type .interaction--girls:last-of-type .ii-sub-menu {
//     top: unset;
//     bottom: 18px;
//   }
//   .timeline__group:last-of-type .interaction--girls:last-of-type .ii-menu__tooltip {
//     bottom: unset;
//     top: 22px;

//     &::after {
//       bottom: unset;
//       top: -12px;
//       border-top-color: transparent;
//       border-bottom-color: #0092F2;
//     }
//   }

// //  this part for last message in chat 
// //  to fit in viewport of timeline in all possible cases. 

//   .timeline__group:last-of-type .interaction--webmaster:last-of-type .ii-sub-menu {
//     top: unset;
//     bottom: 18px;
//   }
//   .timeline__group:last-of-type .interaction--webmaster:last-of-type .ii-menu__tooltip {
//     bottom: unset;
//     top: 22px;

//     &::after {
//       bottom: unset;
//       top: -12px;
//       border-top-color: transparent;
//       border-bottom-color: #0092F2;
//     }
//   }


// .timeline__group:last-of-type .interaction:last-of-type:not(:first-of-type) .ii-sub-menu {
//   top: unset;
//   bottom: 18px;
// }
.timeline__group:last-of-type .interaction:last-of-type .ii-sub-menu {
  top: unset;
  bottom: 27px;

  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    height: 5px;
    width: 100%;
    background-color: transparent;
  }
}
.timeline__group:last-of-type .interaction:last-of-type .ii-menu__tooltip {
  bottom: unset;
  top: 31px;

  &::after {
    bottom: unset;
    top: -12px;
    border-top-color: transparent;
    border-bottom-color: $color-black-squeeze-light;
  }
}