@import 'assets/scss/variables.scss';

.bookings-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $color-mystic;

  &__no-items {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__spinner-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__header {
    display: flex;
    flex-direction: column;
    padding: 14px 5px 19px 5px;

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__buttons {
    display: flex;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    margin-right: 15px;
    padding: 6px;
    background-color: $color-white;

    &--active {
      background-color: rgba(#88D6D9, 0.44);
    }
  }

  &__icon {
    fill: $color-gray;
    width: 20px;
    height: 20px;

    &-pre-bookings {
      fill: $color-persian-pink;
    }

    &-agent-bookings {
      fill: $color-seance;
    }

    &-cancel {
      width: 10px;
      height: 10px;
      fill: $color-persimmon;
    }
  }

  &__cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    cursor: pointer;
    background-color: $color-white;
    border: 1px solid $color-persimmon;
    border-radius: 50%;

  }

  &__search-box {
    margin-top: 11px;
    & input {
      border-color: $color-white;
    }
  }

  &__counter-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__counter {
    margin-right: 4px;
    font-size: 12px;
    letter-spacing: 0.5px;
  }

  &__list {
    margin-right: 15px;
  }

  &__list-wrapper {
    width: 100%;
    height: 100%;
    padding-left: 15px;
  }
}

.bookings-list-item {
  background-color: $color-white;
  border-radius: 6px;
  font-size: 12px;

  &:not(:last-child){
    margin-bottom: 12px;
  }

  &__header {
    position: relative;
    padding: 2px 6px;
    display: flex;
    border-radius: 6px;
    border: 2px solid $color-persimmon;
    
    &::after {
      content: "";
      position: absolute;
      bottom: -8px;
      left: -2px;
      border: 7px solid transparent;
      border-left-color: $color-persimmon;
    }
    &::before {
      content: "";
      position: absolute;
      bottom: -8px;
      right: -2px;
      border: 7px solid transparent;
      border-right-color: $color-persimmon;
    }
  }

  &__time {
    min-width: 70px;
    margin-right: 16px;
  }

  &__operator-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $color-azure-radiance;
  }

  &__content {
    padding: 3px 15px 11px 15px;
    border-radius: 6px;
    border-right: 1px solid $color-persimmon;
    border-left: 1px solid $color-persimmon;
    line-height: 16px;
  }

  &__info {
    color: $color-bali-hai;
  }

  &__girl-name {
    margin: 5px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $color-azure-radiance;
  }

  &__client-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $color-dodger-blue;
  }

  &__type {
    text-transform: capitalize;
  }

  &__icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    min-width: 87px;
  }

  &__icon-pre-booking {
    width: 14px;
    height: 14px;
    min-width: 14px;
    fill: $color-persian-pink;
  }

  &__header-info {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    overflow: hidden;
  }
}

.bookings-list .scrollbars-track {
  right: 5px;
}

.bookings-list .search-input__icon {
  fill: $color-gray;
}