@import '../../../../assets/scss/variables.scss';

.adr-book-list-sort-select {
  position: relative;

  &__input {
    display: none;
  }

  &__input-wrap {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    padding: 0 14px;
    cursor: pointer;
  }

  &__input-value {
    margin-left: 10px;
  }

  &__indicator {
    width: 13px;
    height: 9px;
    color: $color-bermuda;
  }

  &__list {
    z-index: 2;
    position: absolute;
    top: 24px;
    right: 0;
    width: 200px;
    padding: 26px 10px;
    font-weight: normal;
    background-color: $color-white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12);
  }

  &__option {
    width: 100%;
    margin-bottom: 2px;
    padding: 5px;
    border-radius: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: $color-athens-gray;
    &--focused {
      color: $color-black;
      cursor: pointer;
      background-color: $color-silver;
    }
    &--active {
      color: $color-bermuda;
    }
  }
}