.tri-state-switch {
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 9px;
  transition: all 0.4s ease-in-out;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: #ffffff;
    transition: .4s;
  }
  
  &--off {
    background-color: #f8737f;

    &::before{
      right: 13px;
    }
  }

  &--waiting { 
    background-color: #c4c4c4;

    &::before{
      right: 50%;
      transform: translate(50%, -50%);
    }
  }

  &--available {
    background-color: #00A562;

    &::before {
      right: 2px;
    }
  }

  input {
    position: absolute;
    height: 0;
    width: 0;
    overflow: hidden;
  }

  label {
    padding: 6px 4px;
    transition: all 0.4s ease-in-out;
  }

  label:hover {
    cursor: pointer;
  }
}