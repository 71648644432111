@import 'assets/scss/variables';

.chat-sources {
  $this: &;

  position: relative;
  display: flex;
  background-color: $color-athens-gray;
  padding-right: 25px;
  height: 38px;

  &__source-btn {
    // padding: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    color: $color-gray;

    &--selected {
      background-color: #fff;
      color: $color-bondi-blue;

      &:after {
        content: '';
        position: absolute;
        z-index: 5;
        bottom: -2px;
        width: 100%;
        height: 2px;
        background-color: $color-bondi-blue;
        opacity: 1;

        #{$this}--search & {
          transition: opacity ease-in 0.2s;
          opacity: 0;
        }
      }
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
  }

  &__count {
    user-select: none;
    position: absolute;
    top: 50%;
    right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: $color-froly;
    color: #fff;
    font-family: Roboto;
    font-size: 10px;
  }


  .search-input {
    position: initial;

    &__input {
      position: absolute;
      right: 11px;
      display: inline-block;
      transition: all 0.2s linear;
      padding: 0 25px 0 10px;
      width: 0;
      height: 100%;
      outline: none;
      border: 1px solid transparent;
      border-radius: 6px;
      background: none;
      letter-spacing: 0.88px;
      color: $color-tundora;
      font-size: 14px;
    }
  }

  &__search-form {
    width: 10%;
    height: 100%;
    position: absolute;
    padding: 0;
    right: 0;
    transition: all 0.2s linear;

    &--open {
      width: 100%;
      background-color: $color-mystic;

      .search-input__input {
        width: calc(100% - 20px);
        background: #fff;
        padding-right: 60px;
        border: 1px solid $color-border-focus;  
      }
    }
  }

  &__search-btn {
    position: absolute;
    right: 22px;
    top: 9px;
    display: flex;
    padding: 0;
    transition: all 0.2s linear;

    &-icon {
      width: 20px;
      height: 20px;
      color: $color-gray;

      &--active {
        color: rgba($color-bondi-blue, .5);
      }
    }
  }
  &__clear-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 48px;
    display: flex;
    padding: 5px;
    cursor: pointer;

    &-icon {
      width: 10px;
      height: 10px;
      color: $color-persimmon;
    }
  }
}

.sales-popup {
  position: absolute;
  left: 0;
  top: auto;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2);
  padding: 7px 10px 10px;
  z-index: 9;
  display: flex;
  align-items: center;

  &__btn {
    color: #fff;
    background-color: #0092f2;
    border-radius: 5px;
    font-size: 13px;
    line-height: 1.3;
    user-select: none;
    padding: 6px 10px;
    margin-bottom: 5px;
    max-width: 200px;

    &--cancel {
      margin-left: 10px;
      background-color: #bfbfbf;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: #bfbfbf;
    }
  }
}
