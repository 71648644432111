.mail-sidebar {
  flex-shrink: 0;
  width: 200px;
}

.mail-sidebar__compose-link {
  margin-bottom: 24px;
  padding: 10px 22px;
  display: inline-flex;
  align-items: center;

  background-color: #e6ebf1;
  border-radius: 6px;

  font-size: 14px;
  letter-spacing: 0.88px;
  color: #000;
  text-decoration: none;

  &--active {
    pointer-events: none;     // to test
  }
}

.mail-sidebar__compose-icon {
  width: 12px;
  height: 12px;
  margin-left: 35px;

  fill: #00a6ad;
}

.mail-sidebar__link-list {
  li:not(:last-child) {
    margin-bottom: 10px;
  }
}

.mail-sidebar__link {
  display: flex;
  align-items: center;

  text-decoration: none;
}

.mail-sidebar__icon-wrap {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background-color: #e6ebf1;
  transition: background-color 250ms;

  .mail-sidebar__link--active & {
    background-color: #88d6d9;
  }

  svg {
    width: 20px;
    height: 20px;

    fill: #404040;
    transition: fill 250ms;

    .mail-sidebar__link--active & {
      fill: #fff;
    }
  }
}

.mail-sidebar__item-text {
  transition: color 250ms;

  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #808080;

  .mail-sidebar__link--active & {
    color: #000;
  }
}

.mail-sidebar__count {
  margin-left: auto;

  font-size: 14px;
  color: #000;
}

.mail-sidebar__undo-actions-wrap {
  margin-top: 60px;
}
