/* Reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

html {
  box-sizing: border-box;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

button {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  font-family: Roboto, sans-serif;
}

.popup-menu {
  box-shadow: $shadow-primary;
  background-color: $color-white;
  color: $color-gray;
  border-radius: 6px;
  padding: 0;

  &__item {
    padding: 11px 15px;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 1px;
    cursor: pointer;

    &:nth-child(even) {
      background-color: $color-athens-gray;
    }

    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    
    &:hover {
      background-color: $color-bermuda;
      cursor: pointer;
    }
  }
}

.divider {
  position: relative;
  width: 100%;
  height: 8px;
  background-image: url(../images/separator-horizontal.svg);
  background-size: contain;
  background-repeat: repeat;
}

.round-img {
  border-radius: 50%;
  background: $color-white;
}

.hidden {
  display: none;
}

.react-select {
  color: $color-gray;
  $block: &; // For increase specificity

  #{$block}__control {
    padding-left: 0;
    min-height: 30px;
    border: none;
    box-shadow: none;
    border-radius: 6px 6px 0 0;
    background-color: transparent;

    &--menu-is-open {
      background-color: $color-white;

      #{$block}__dropdown-indicator svg {
        transform: rotate(180deg);
        color: $color-bondi-blue;
      }
    }
  }

  #{$block}__clear-indicator {
    padding: 0;
  }

  #{$block}__dropdown-indicator {
    padding: 0 10px;
    color: $color-bondi-blue;

    &:hover {
      color: $color-bondi-blue;
    }
  }

  #{$block}__value-container {
    padding: 0;
  }

  #{$block}__menu {
    z-index: 50;
    margin: 0;
    padding: 0;
    width: max-content;
    min-width: 100%;
    box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);
    border-radius: 0 0 6px 6px;
    
    &-list {
      padding: 0;
    }
  }

  #{$block}__option {
    padding: 11px 15px;
    letter-spacing: 1px;
    font-size: 12px;
    background-color: $color-white;
    cursor: pointer;

    &:active {
      background-color: inherit;
    }

    &:hover {
      background-color: $color-bermuda !important;
    }

    &:nth-child(even) {
      background-color: $color-athens-gray;
    }

    &:last-child {
      border-radius: 0 0 6px 6px;
    }

    &--is-focused {
      color: inherit;
      background-color: inherit;
    }

    &--is-selected {
      background-color: inherit;
      color: $color-bondi-blue;
    }
  }

  #{$block}__single-value {
    color: $color-gray;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
  }

  &__loading-indicator {
    display: flex;
    padding: 11px;
  }

  #{$block}__indicator-separator {
    display: none;
  }
}

/* Visually Hidden */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

/* Animations */
.animated-init{
  animation: slideInRight 0.5s;
}
.animated-init-left{
  animation: slideInLeft 0.5s;
}
.animated-init-bottom{
  animation: slideInBottom 0.5s;
}

@keyframes slideInRight{
  from {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft{
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInBottom{
  from {
    opacity: 0;
    transform: translate3d(0, 40%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
