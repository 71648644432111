@import 'assets/scss/variables.scss';

.mail-header {
  display: flex;
  align-items: center;
  min-height: 84px;
  padding: 0 20px 0 30px;
  background-color: $color-mystic;

  &__email {
    flex-shrink: 0;
    width: 200px;
  }

  &__search-input-wrap {
    min-width: 370px;
    margin-left: 20px;
    margin-right: 70px;
  }

  &__search-input {
    width: 100%;
    padding: 10px 50px 10px 10px;
    border: solid 1px $color-bermuda;
    border-radius: 6px;
    outline: none;
    color: $color-black;
    font-size: 14px;
    letter-spacing: 0.88px;
    background-color: $color-athens-gray;

    &::placeholder {
      color: $color-silver;
    }
  }

  &__actions {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-left: 60px;
  }

  &__move-to-wrap {
    position: relative;
    margin-right: 12px;
  }

  &__move-to-popup-wrap {
    width: 100%;
    z-index: 15;
    border-radius: 6px;
    box-shadow: 0 2px 0 0 $color-bondi-blue;
  }

  &__action-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 22px;
    max-height: 32px;
    margin-right: 17px;
    color: $color-black;
    font-size: 14px;
    letter-spacing: 0.88px;
    white-space: nowrap;
    background-color: $color-athens-gray;
    border-radius: 6px;
    transition: all .2s, visibility 0s;

    &-icon {
      width: 15px;
      height: 15px;
      margin-left: 10px;
      color: $color-bondi-blue;

      &--send {
        color: $color-athens-gray;
      }

      &--move {
        width: 16px;
        height: 16px;
      }
    }

    &--send {
      background-color: $color-bondi-blue;
      color: $color-white;
      font-weight: 500;
    }

    &--main {
      margin-right: 14px;
    }

    &--move {
      margin-right: 0;
      border: 2px solid transparent;

      .mail-header__move-to-wrap--move-menu-active & {
        border: 2px solid $color-bondi-blue;
      }
    }

    &--save {
      min-width: 142px;
      cursor: default;

      &:disabled {
        background-color: transparent;
      }
    }

    &--hidden {
      visibility: hidden;
    }
  }
}
