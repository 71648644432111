@import 'assets/scss/variables';

.timeline {
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-grow: 1;

  &__system-msg {
    margin-bottom: 12px;
  }
  &__load-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 15px;
    height: 100%;
    font-size: 13px;
    color: #bfbfbf;
  }
  &__group {
    max-width: 100%;
  }
  &__list {
    list-style: none;
    padding: 10px;
    margin: 0;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  &__scroll-btn {
    $color: rgba($color-bondi-blue, 0.7);

    position: absolute;
    z-index: 2;
    right: 15px;
    bottom: 24px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 26px;
    height: 26px;
    border: 2px solid $color;
    border-radius: 6px;
    box-shadow: 0 0 14px 0 rgba(0,166,173,0.35);
    background-color: #fff;
    cursor: pointer;
    color: $color;

    &-icon {
      width: 16px;
      height: 16px;
      transform: rotate(180deg);
    }
  }
  &__btn {
    position: absolute;
    cursor: pointer;
    bottom: 20px;
    right: 10px;
    border: 1px solid grey;
    background: rgba(0, 0, 0, 0.48);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    outline: none;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;

    &--hidden {
      display: none;
    }
    &--search {
      border: none;
      background: #c0c4c8;
      cursor: pointer;
      outline: none;
    }
    &--global-search {
      margin-top: 28px;
      padding: 5px 14px;
      border-radius: 6px;
      background-color: $color-bondi-blue;
      letter-spacing: 1px;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
    }
  }
  &__update-spinner {
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
  }
  &__unread-count {
    position: absolute;
    top: -11px;
    background: #fff;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    outline: none;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid red;
    font-size: 10px;
  }
  &__unread-divider {
    display: none;
    margin: 0 auto 5px;
    padding: 2px 10px;
    width: max-content;
    border: 1px solid $color-bondi-blue;
    border-radius: 6px;
    text-align: center;
    color: $color-tundora;
    font-size: 12px;
  }
  &__unread-divider:first-of-type {
    display: block;
  }

  .scrollbars-track {
    right: 3px;
  }

  &__no-results {
    text-align: center;
    line-height: 32px;
    color: $color-gray;
    font-size: 24px;
  }
  &--pinned-msgs {
    padding-top: 48px;
  }
}

// incoming msg
.chat:not(.public-chat) .interaction--inc .interaction__body-wrap {
  background-color: $color-black-squeeze !important;

  &--deleted {
    background-color: transparent !important;
  }
}

// interactions
.interaction {
  position: relative;
  margin-bottom: 12px;
  cursor: default;
  border-radius: 5px;

  .interaction__status {
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  &--inc {
    .interaction__text {
      color: $color-tundora-light;
    }

    .interaction__status {
      margin: 0 5px;

      &--deleted {
        color: $color-red-orange;
        line-height: 1;
      }
    }
  }

  &--out {
    .interaction__text {
      color: $color-gray;
    }

    .interaction__status {
      margin-bottom: 1px;
      margin-left: 5px;

      &-icon {
        width: 10px;
        height: 10px;

        &--pending {
          color: $color-silver;
        }

        &--sent {
          color: $color-bermuda;
        }

        &--delivered {
          color: $color-bermuda;
        }

        &--delivered:nth-child(2) {
          transform: translateX(-50%);
        }

        &--failed {
          color: $color-persimmon;
        }
      }

      &--error {
        color: $color-persimmon;
        margin-bottom: 0;
        margin-right: 5px;

        .interaction__status-icon {
          margin-right: 5px;
        }
      }

      &--deleted {
        color: $color-red-orange;
        margin-bottom: 0;
        margin-right: 5px;
        line-height: 1;
      }
    }

    .interaction__body-wrap {
      &--deleted {
        background-color: transparent;
      }
    }
  }

  &--deleted {
    pointer-events: none;
  }

  // &:hover {
  //   .interaction__body-wrap {
  //     background-color: #fff;
  //     box-shadow: 0px 10px 26px -12px #00000047;
  //   }
  // }

  &:hover &__menu {
    display: flex;
  }

  &--girls {
    padding: 0px;
    background-color: unset;
    box-shadow: unset;
    margin: 0 -10px 5px;
    // overflow: hidden;
    // text-overflow: ellipsis;

    &:hover {
      background-color: #fff;

      & .interaction__text {
        background-color: rgba(226, 226, 230, 0.4);
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    .ii-menu {
      right: 15px;

      &__tooltip {
        left: -35px;
        &:after {
          left: 38px;
        }
      }
    }

    & .interaction__header {
      align-items: flex-start;
      padding: 2px 10px;
    }

    & .interaction__ava {
      margin-left: 0;

      &::after {
        box-sizing: border-box;
        display: inline-block;
        width: 10px;
        height: 10px;
        position: absolute;
        border-radius: 50%;
        top: -2px;
        left: -2px;
        border: 2px solid rgb(230, 235, 241);
        // animation: available-pulse 1s infinite;
        transition: all 0.2s ease-in;
      }

      &--available {
        &::after {
          content: "";
          background-color: rgb(1, 223, 133);
        }
      }

      &--off {
        &::after {
          content: "";
          background-color: #f8737f;
        }
      }
    }

    & .interaction__main-wrap {
      flex-grow: 1;
      // overflow: hidden;
      // text-overflow: ellipsis;
      cursor: pointer;
      width: calc(100% - 37px);
    }

    & .interaction__main-info {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
    & .interaction__time--msg {
      white-space: nowrap;
    }
    & .interaction__text {
      background-color: #fff;
      color: #000;
      padding: 3px;
      font-size: 13px;
      margin-top: 4px;
      font-size: 11px;
      display: -webkit-box;
      height: 18px;
      transition: all 0.1s;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
      width: 100%;
    }

    & .interaction__text--expanded {
      background-color: #fff;
      color: #000;
      padding: 3px 0;
      font-size: 14px;
      margin-top: 2px;
      display: block;
      height: auto;
      transition: all 0.1s;
    }
    .interaction__text--inavailable {
      background-color: #01df8652 !important;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .interaction__text--inoff {
      background-color: #c591913b !important;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .interaction_text-wrap {
      display: flex;
      align-items: center;
    }
    .interaction__glossary-drop {
      margin-right: 3px;
      margin-top: 2px;
      &--open {
        color: #0092f2;
      }
    }
    .interaction__glossary-undo {
      color: #0092f2;
      font-size: 12px;
      text-decoration: underline;
      margin-top: 4px;
      margin-right: 5px;
    }
  }

  &--new.interaction--out {
    animation: newOutgoingMsg 0.15s linear;
  }
  &--new.interaction--inc {
    animation: newIncomingMsg 0.15s linear;
  }

  &--out {
    background-color: transparent;
    // text-align: right;
    box-shadow: none;
  }

  &__textarea--hidden {
    width: 0;
    height: 0;
    resize: none;
    border: none;
    position: absolute;
    z-index: -1;
    overflow: hidden;
    outline: none;
    background-color: transparent;
  }

  &__header {
    display: flex;
    align-items: flex-end;
    // padding: 0 8px;
    font-size: 12px;
  }

  &__header-info {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    padding-bottom: 3px;

    &--reverse {
      flex-direction: row-reverse;

      .ii-menu {
        left: 0;
      }
      .ii-sub-menu {
        left: 0;
      }
      .ii-menu__tooltip {
        right: initial;
        left: -6px;

        &:after {
          right: initial;
          left: 10px;
        }
      }
    }
  }

  &__ava {
    margin: 8px 0 0 8px;
    width: 32px;
    min-width: 32px;
    height: 32px;
    cursor: pointer;
    position: relative;

    img {
      background-color: #fff;
      border-radius: 50%;
    }

    &--reverse {
      margin: 8px 8px 0 0;
    }

    // svg {
    //   position: absolute;
    //   bottom: 0px;
    //   left: -7px;
    //   border: 2px solid #e6ebf1;
    //   background-color: white;
    //   border-radius: 50%;

    //   &.interaction__icon--right {
    //     left: unset;
    //     right: -7px;
    //   }
    // }

    // svg {
    //   position: absolute;
    //   top: 0px;
    //   right: 0px;
    //   border: 1px solid #fff;
    //   background-color: #fff;
    //   border-radius: 50%;
    // }

    &-telegram-icon {
      position: absolute;
      top: -8px;
      left: -8px;
      width: 18px;
      height: 18px;
      color: $color-azure-radiance;
      border-radius: 50%;
      border: 1px solid $color-mystic;

      &--reverse {
        left: initial;
        right: -8px;
      }
    }
  }

  &__name {
    display: flex;
    align-items: flex-end;
    font-size: 12px;
    color: $color-dodger-blue;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 5px;

    &--out {
      color: $color-gray;
    }

    &--exclusive {
      margin-left: 2px;
      color: #f2cc0c;
      font-size: 9px;
    }

    &--is-sender {
      margin-right: 22px;
      padding-top: 3px;

      svg {
        width: 17px;
        height: 17px;
        color: $color-azure-radiance;
        margin-left: 5px;
        position: absolute;
        right: 0px;
        // top: 9px;
        // right: 4px;
        // border: 2px solid #e6ebf1;
        // background-color: white;
        // border-radius: 50%;
      }
    }
  }
  &__rates {
    margin-left: 5px;
  }
  &__status {
    margin-left: 10px;
    font-size: 11px;

    &--reverse {
      margin: 0 10px;
      font-size: 11px;
    }
  }
  &__time {
    // font-style: italic;
    color: $color-tundora-light;
    font-size: 10px;
    margin: 0 5px;

    & time {
      text-decoration: underline;
    }
  }
  &__channel {
    margin: 0 5px;
    font-size: 11px;
  }
  &__body-wrap {
    position: relative;
    margin-top: 8px;
    border-radius: 6px;
    background-color: #fff;

    &--deleted {
      opacity: 0.3;
      outline: 1px solid red;
    }
  }
  &__replied-msg {
    padding: 7px 10px 5px;
    text-align: left;
  }
  &__text-wrap {
    padding: 12px 25px;
    word-break: break-word;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-align: left;
  }
  &__operator-info {
    display: flex;
    align-items: center;
    line-height: 1.4;
    color: #858181;
    margin: 5px 0 0;
    font-size: 14px;
    white-space: pre-wrap;
    flex-wrap: wrap;
    flex-grow: 1;
  }
  &__operator-info-img {
    border-radius: 50%;
    overflow: hidden;
    height: 18px;
    width: 18px;
    margin-left: 10px;
  }
  &__operator-name {
    color: #0092f2;
    font-weight: bold;
    font-size: 14px;
    margin-left: 10px;
  }
  &__voicemail {
    margin-left: auto;
  }
  &__text {
    line-height: 19px;
    margin: 0;
    color: $color-tundora-light;
    font-size: 14px;
    white-space: pre-wrap;

    &--removed svg {
      margin: 0 5px;
    }
    &--deleted {
      text-decoration: line-through;
    }
    &--pinned {
      font-size: 12px;
      color: $color-text-primary;
      line-height: 16px;
    }
  }
  &__link {
    display: block;
    margin-top: 2px;
    color: #0092f2;
    font-size: 10px;
    line-height: 1.6;
    word-break: break-word;
  }
  &__clickable-name {
    text-decoration: underline;
    color: #0092f2;
    cursor: pointer;
  }
  &__img-wrap {
    width: 100px;
    height: 70px;
    object-fit: cover;
    cursor: pointer;
    display: flex;
    text-align: center;
    margin-top: 5px;
  }
  &__preload-img {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 4px;

    &:hover {
      filter: blur(0.005px);
      transform: scale(0.95);
    }
  }
  &__edited {
    display: inline-block;
    font-size: 10px;
    margin-left: 7px;
  }

  &__deleted {
    display: inline-block;
    font-size: 10px;
    margin-left: 7px;
  }

  &__mention {
    font-weight: 400;
    color: #0092f2;
  }

  &__glossary {
    padding: 0;
    list-style: none;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    background-color: #fff;
    z-index: 2;
    width: 100%;
    font-size: 12px;
    box-shadow: 0px 7px 7px 0px #00000027;

    &-item {
      border: none;
      position: relative;
      padding: 12px 12px 12px 70px;
      color: #0092f2;
      cursor: pointer;

      &:last-child::before {
        display: none;
      }
      &--disabled {
        background: #c591913b;
        cursor: not-allowed;
      }

      &:hover {
        background-color: #f6f6f6;
      }

      &::before {
        content: "";
        background: #bfbfbf;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        width: calc(100% - 100px);
        box-sizing: border-box;
      }

      &:nth-child(1) {
        &::after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #01df85;
          left: 50px;
        }
      }

      &:nth-child(2) {
        &::after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #f8737f;
          left: 50px;
        }
      }
    }
  }
  &__icon-pinned {
    width: 14px;
    height: 14px;
    color: $color-bondi-blue; 
    transform: rotate(45deg);
  }
  &__btn-pinned {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.interaction[data-isanimate="true"] {
  animation: shakeMe 0.8s linear infinite;
}

.interaction-reminder {
  border: 1px solid #0092f2;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  position: relative;

  &__close {
    cursor: pointer;
    border: none;
    outline: none;
    background-color: none;
    z-index: 1;
    padding: 15px;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 15px;
    width: 15px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 4px;
      height: 15px;
      width: 2px;
      background-color: red;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }

  .interaction__menu.i-menu {
    display: none;
  }

  &__time {
    color: #0092f2;
    margin-left: 5px;
  }

  &__title {
    line-height: 1.4;
    margin: 5px 0;
    color: rgb(133, 129, 129);
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #0092f2;
    padding-right: 25px;
  }
  &__content {
    -border: 1px solid #0092f2;
    -border-radius: 5px;
    padding: 5px 15px 2px;
  }
}

.shared-msg {
  border-left: 3px solid #d9d9d9;
  padding-left: 10px;
  margin-bottom: 8px;

  &__header {
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }
  // &__content {

  // }
}

.attachments- {
  margin-top: 6px;
  text-align: left;
  font-size: 14px;

  &__wrap {
    border-left: 3px solid #d9d9d9;
    padding-left: 10px;
  }
  // &__link-wrap {}
  &__row {
    display: flex;
  }
  &__icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  &__images {
    display: flex;
    flex-wrap: wrap;
  }
  &__voice-message {
    width: 100%;
    max-width: 210px;
    margin: auto;
    font-size: 12px;
    font-family: Roboto;
    color: gray;
    margin-bottom: 10px;

    .player {
      margin-top: 2px;
    }
  }
  // &__title{
  //   margin: 5px 0;
  //   color:#1264a3;
  // }
  &__desc {
    margin: 0 0 5px;
    line-height: 1.3;
  }
  &__img {
    max-width: 100px;
    height: 100px;
    max-height: 100px;
  }
  &__img-wrap {
    width: 100px;
    height: 70px;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    text-align: center;
    margin: 5px 0;
  }
}

@keyframes newOutgoingMsg {
  from {
    transform: translate3d(50%, 50%, 0) scale(0.1);
  }
  to {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes newIncomingMsg {
  from {
    transform: translate3d(-50%, 50%, 0) scale(0.1);
  }
  to {
    transform: translate3d(0, 0, 0) scale(1);
  }
}