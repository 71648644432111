@import 'assets/scss/variables';

.main-page {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - #{$header-height});

  &__calls {
    display: flex;
    height: 48px;
    outline: none;
    overflow: hidden;
    user-select: text;
    bottom: 0px;
    flex-direction: column;
    min-height: 100%;
    top: 0px;
    width: 100%;

    position: static !important;
    
    &--show {
      height: 164px;
    }
  }

  &__main {
    display: grid;
    grid-template-columns: 280px minmax(900px, 1fr) 280px;
    flex-grow: 1;
    padding-top: 24px;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 20px;
  }

  &__chats {
    display: flex;
    justify-content: space-between;
    gap: 21px;
    padding: 0 8px;
  }

  &__divider {
    margin: 10px 0;
  }

  &__contacts {
    height: 70%;
  }

  &__sessions {
    height: 30%;
  }
}

.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;  
  margin: 5px;
  height: calc(100% - 10px);
  background-color: rgba($color-bermuda, 0.4);
  border-radius: 20px;
}