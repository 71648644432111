.search-list {
  width: 370px;
  height: 690px;
  display: flex;
  flex-direction: column;

  background-color: #E6EBF1;
  border-bottom: 2px solid #00A9AF;
  box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);
  border-radius: 6px;
  text-align: initial;

  &__header {
    padding: 32px 20px 24px 20px;
    flex-shrink: 0;
  }

  &__input-label {
    margin-bottom: 6px;
    display: inline-block;

    font-size: 12px;
    letter-spacing: 1px;
  }

  &__main {
    padding: 12px 5px 0 15px;
    flex-grow: 1;

    background-color: #fff;
    border-radius: 6px;

    &--centered {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .track-vertical {
      bottom: 10px;
      width: 5px;
    }
    
    .thumb-vertical {
      border: none;
      background-color: #808080;
    }
  }

  &__no-items {
    font-size: 16px;
    color: #808080;
  }

  &__infinite-scroll {
    padding: 0 10px 8px 0;
    
    > :not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__bottom-spinner {
    margin: 0 auto;
    display: block;
  }
}
