.lazy-load {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    // background: #fff;
    border-radius: 50%;
  }

  &__image {
    display: block;

    &--hidden {
      display: none;
    }
  }

  &__spinner{
    width: 100%;
    height: 100%;
  }
}
