@import "assets/scss/variables";

.mail-attachment {
  display: flex;
  border: 1px solid rgba(#000, 0.15);
  border-radius: 4px;
  background-color: #fff;
  height: 100%;

  &__info {
    position: relative;
    overflow: hidden;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    padding: 5px 10px;

    &::after {
      content: "";
      position: absolute;
      top: 5px;
      bottom: 5px;
      right: 0;
      border-right: 1px solid rgba(#000, 0.15);
    }

    .mail-attachment--large & {
      padding: 10px;

      &::after {
        top: 10px;
        bottom: 10px;
      }
    }
  }

  &__icon {
    display: flex;
    color: #404040;
    font-size: 20px;

    .mail-attachment--large & {
      font-size: 30px;
    }
  }

  &__text {
    margin-left: 8px;
    overflow: hidden;
    display: flex;

    .mail-attachment--exist & {
      display: block;
    }
  }

  &__name {
    margin: 0;
    font-size: 14px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__size {
    flex-shrink: 0;
    margin: 0 0 0 8px;
    font-size: 14px;
    opacity: .6;

    .mail-attachment--exist & {
      margin-left: 0;
      font-size: 11px;
    }
  }

  &__spinner {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
  }

  &__remove {
    flex-shrink: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    font-size: 15px;
    color: #404040;

    &:hover {
      color: blue;
    }

    &:disabled {
      opacity: .5;
      cursor: default;
      color: #404040;
    }
  }

  &__download {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    font-size: 15px;
    color: #404040;

    .mail-attachment--large & {
      width: 50px;
      font-size: 20px;
    }
  }
}
