@import "assets/scss/variables.scss";

.mail-contact-list {
  height: 100%;

  &__list {
    @include scrollBars;

    overflow-y: scroll;

    &::-webkit-scrollbar-button {
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      background: #808080;;
      border-radius: 6px;
    }
  }

  &--centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
