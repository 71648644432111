.mail-page {
  display: flex;
  flex-direction: column;
  min-width: 1094px;
  height: calc(100vh - 62px);

  &__bottom-wrap {
    padding: 20px 30px 0;
    flex-grow: 1;
    display: flex;
  }
}
