@import 'assets/scss/variables';

.clipboard-btn {
  display: inline-flex;

  &__input {
    top: -9999px;
    left: -9999px;
    opacity: 0;
    pointer-events: none;
    position: absolute
  }

  &__icon {
    width: 13px;
    height: 13px;
    color: $color-gray;

    &--check {
      color: $color-bondi-blue;
    }
  }

  &__btn {
    display: inline-flex;
    padding: 0;
  }
}