// Colors
$color-white: #fff;
$color-white-opacity: rgba(255, 255, 255, 0.5);
$color-black: #000;
$color-alto: #d1d1d1b6;
$color-bermuda: #88D6D9;
$color-bermuda-opacity: rgba(136,214,217,0.3);
$color-bondi-blue: #00A6AD;
$color-persian-green: #00949a;
$color-caribbean-green: #01DF85;
$color-mystic: #E6EBF1;
$color-wild-sand: #f6f6f6;
$color-geyser: #dae0e6;
$color-tundora: #404040;
$color-mine-shaft: #222;
$color-shark: #2e2e30;
$color-tundora-light: #4a4a4a;
$color-gray: #808080;
$color-alto: #D8D8D8;
$color-gallery : #EEEEEE;
$color-persimmon: #FF5E57;
$color-athens-gray: #F6F6F8;
$color-jagged-ice: #c9e4e9;
$color-viking: #5ac0de;
$color-azure-radiance: #0092F2;
$color-dodger-blue: #3565FD;
$color-silver: #bfbfbf;
$color-selective-yellow: #fbbc06;
$color-bali-hai: #8C97B2;
$color-jade: #00B936;
$color-green-haze: #00A562;
$color-fun-green: #008F14;
$color-apple: #2BC741;
$color-jade: #03c275;
$color-caribian-green: #01df85;
$color-spring-green: #29F18E;
$color-mandy: #e75d68;
$color-froly: #F8737F;
$color-rose-bud: #f9a9b0;
$color-red: #F20C0C;
$color-pomegranate: #f01e1e;
$color-red-orange: #FF3D2B;
$color-dawn-pink: #f6e9ec;
$color-mint-talib: #C5E9F4;
$color-zanah: #DDEFDD;
$color-ripe-lemon: #F2CC0C;
$color-black-squeeze-light: #F6F9FC;
$color-black-squeeze: #E7F6F7;
$color-seance: #88259C;
$color-pink: #E064F1;
$color-persian-pink: #F771A8;
$color-neon-carrot: #FF8C37;
$color-iceberg: #CBEDEF;
$color-tahiti-gold: #E88204;
$color-lucky-point: #1D1F7A;

// Config
$color-text-primary: $color-tundora;
$color-text-primary-light: $color-gray;
$color-text-primary-darkest: $color-black;

$color-border-primary: $color-silver;
$color-border-success: $color-jade;
$color-border-error: $color-froly;
$color-border-focus: $color-bondi-blue;

$shadow-primary: 0 12px 12px 0 rgba(0, 0, 0, .12);
$shadow-focus: 0 0 0 1px rgba($color-border-focus, 0.3);

$header-height: 62px;


@mixin scrollBars($color: $color-silver) {
	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	// &::-webkit-scrollbar-button {
	//   height: 10px;
	// }

	&::-webkit-scrollbar-thumb {
		background: $color;
		border-radius: 3px;
		margin: 5px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: darken($color: $color, $amount: 5%);
	}
}

@mixin webkit-text-overflow ($line-count: 1, $word-break: null) {
	display: -webkit-box;
	-webkit-line-clamp: $line-count;
	-webkit-box-orient: vertical;
	overflow: hidden;

	text-overflow: ellipsis;

	@if ($word-break != null) {
			word-break: $word-break;
	} @else if $line-count == 1 {
			word-break: break-all;
	} @else {
			word-break: break-word;
	}
}

@mixin btnInteraction($background-color, $darkenAmount: 2) {
	transition: .2s cubic-bezier(0.3, 0, 0.5, 1);
	transition-property: color, background-color, border-color;

	&:hover {
		background-color: darken($background-color, $darkenAmount);
	}

	&:focus-visible, &:active {
		outline: 3px solid rgba($background-color, 0.3);
	}
}

// 📌️ Placeholders

%base-text-overflow {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
