@import 'assets/scss/variables';

.form-text-input {
  position: relative;
  width: 100%;

  &__error {
    position: absolute;
    bottom: -15px;
    left: 0;
    color: $color-red;
    font-size: 11px;
    letter-spacing: 1px;

    &--relative {
      position: relative;
      bottom: 0;
      margin-top: 12px;
    }
  }
}