@import 'assets/scss/variables';

.recently-viewed-profiles {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  width: 49%;
  max-width: 270px;
  height: calc(100% - 10px);

  &__scrollbar-wrap {
    flex-grow: 1;
    width: 100%;
  }
  &__scrollbar-thumb {
    border-radius: 6px;
    background-color: $color-silver;
  }
  &__title {
    color: $color-gray;
    font-size: 14px;
  }
  &__items {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 25px;
    padding: 0;
  }
  &__item {
    display: flex;
    opacity: 0.5;
    overflow: hidden;
    transition: opacity linear 0.1s;
    margin-bottom: 5px;
    border: 2px solid $color-black-squeeze-light;
    border-radius: 6px;
    background-color: $color-mystic;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
  &__img-wrap {
    max-width: 60px;
    height: 100px;
    width: 30%;
  }
  &__info {
    padding: 6px;
    padding-left: 13px;
    font-size: 12px;
    line-height: 16px;
    color: $color-tundora;
  }
  &__name {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-weight: 500;
    letter-spacing: 1px;

    &-icon {
      margin-right: 7px;
      width: 16px;
      height: 16px;
      color: $color-gray;
    }
  }
}

@media (max-width: 1450px) {
  .recently-viewed-profiles {
    max-width: 245px;
  }
}
