@import 'assets/scss/variables';

.operators-chat {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__header {
    padding: 8px 0 6px;
    background-color: $color-mystic;

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px 0 12px;
    }
  }

  &__title {
    font-size: 12px;
    color: $color-gray;
    font-weight: 500;
  }

  &__add-room-btn {
    display: flex;
    padding: 0;
  }
  &__add-room-icon {
    width: 15px;
    height: 15px;
    color: $color-bondi-blue;
  }
  &__edit-room-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    padding: 0;
    width: 18px;
    height: 18px;
  }
  &__edit-room-icon {
    width: 10px;
    height: 10px;
    color: $color-bondi-blue;
  }
  &__delete-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    padding: 0;
    width: 18px;
    height: 18px;
  }
  &__delete-icon {
    width: 15px;
    height: 15px;
    color: $color-froly;
  }

  &__new-room {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  &__participants {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 6px;
    padding: 0 15px;
  }
  &__avatars-wrapper {
    position: relative;
    flex-grow: 1;
    min-width: 0;

    &:after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      height: 100%;
      width: 10px; 
      background: linear-gradient(90deg, rgba(0,0,0,0) 0%, $color-mystic 100%);
    }
  }
  &__avatars {
    position: relative;
    z-index: 1;
    display: flex;
    flex-grow: 1;
    overflow: hidden;
  }
  &__avatar {
    width: 24px;
    height: 24px;
    min-width: 24px;
    border: 2px solid $color-mystic;
    margin-left: -8px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;

    &:first-child {
      margin-left: 0;
    }

    // img {
    //   background-color: red;
    // }
  }
}