@import 'assets/scss/variables';

.context-menu {
  position: fixed;
  list-style: none;
  z-index: 10;

  &__item {
    padding: 0 50px 0 10px;
    min-width: 160px;
    line-height: 36px;
    cursor: default;

    &:active {
        background-color: $color-bermuda;
    }

    &--disabled {
        color: #999999;
        pointer-events: none;
    }
  }
  &__separator {
    width: 100%;
    height: 1px;
    background: #CCCCCC;
    margin: 0 0 0 0;
  }
}