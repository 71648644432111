@import 'assets/scss/variables.scss'; 

.contact-address-tab {
  &__input {
    width: 100%;
    padding: 10px 26px 10px 10px;
    border: 1px solid transparent;
    border-radius: 6px;
    outline: none;
    font-size: 14px;
    line-height: 19px;
    font-family: Roboto;
    letter-spacing: 0;
    background-color: $color-white;
    transition: all 0.15s ease-in-out;

    & textarea {
      display: block;
      max-height: 166px;
      padding: 0px 5px 0px 0px;
      border: none;
      color: $color-tundora;
      font-size: 14px;
      line-height: 19px;
      font-family: Roboto;
      letter-spacing: 0.88px;
      resize: none;
      @include scrollBars;
    }

    &:focus-within {
      border-color: $color-border-focus;
    }
  }

  &__btn {
    &-wrapper {
      padding: 21px 17px;
    }

    &-add {
      display: flex;
      position: relative;
      align-items: center;
      color: $color-gray;

      &-icon {
        width: 20px;
        height: 20px;
        color: $color-bondi-blue;
      }
      
      &::after {
        display: block;
        position: absolute;
        left: 27px;
        width: 110px;
        padding-left: 5px;
        font-size: 14px;
        text-align: left;
        content: "Add Address";
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  &__map-icon-wrap {
    position: absolute;
    top: 0;
    right: 16px;
    bottom: 0;
    margin: auto;
    color: $color-gray;
  }

  &__input-wrap {
    position: relative;
    margin-top: 12px;

    &--error {
      & .contact-address-tab__input {
        border: 1px solid $color-red !important;
      }

       & .form-error-text__wrap {
         position: static;
       }
    }

    &--address {
      margin-top: 7px;
      padding: 0 8px 0 17px;
      &:first-child {
        margin-top: 23px;
      }
    }
  }
}