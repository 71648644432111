.sales-header {
  grid-area: header;
  display: grid;
  grid-template-columns: 280px 600px 1fr;
  align-items: center;

  background-color: #e6ebf1;
}

.sales-header__left-wrap {
  padding: 5px 15px;
}

.sales-header__primary-button {
  padding: 9px 22px;
  display: inline-grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: space-between;
  align-items: center;
  column-gap: 15px;

  background-color: #fff;
  border-radius: 6px;

  font-size: 14px;
  letter-spacing: 0.875px;

  &--full-width {
    width: 100%;
  }

  svg {
    width: 18px;
    height: 18px;
    color: #00A6AD;
  }
}

.sales-header__center-wrap {
  padding: 5px 30px 5px 15px;
}

.sales-header__right-wrap {
  padding: 5px 15px 5px 25px;
  display: grid;
  grid-template-rows: [row-begin] auto auto [row-end];
  grid-template-columns: 150px [col-center] 1fr [col-end];
  gap: 12px 30px;
}

.sales-header__buffer-button-wrap {
  position: relative;
}

.sales-header__secondary-button {
  width: 100%;
  padding: 6px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
  align-items: center;
  column-gap: 12px;

  border: 1px dashed #808080;
  border-radius: 6px;

  font-size: 14px;
  letter-spacing: 0.875px;

  svg {
    width: 16px;
    height: 16px;
    color: #404040;
  }
}

.sales-header__clear-buffer-button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 17px;
  height: 17px;
  display: grid;
  justify-content: center;
  align-items: center;

  background-color: #FF5E57;
  border-radius: 50%;

  &::after {
    $touch-radius: calc(100% + 6px);

    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: $touch-radius;
    height: $touch-radius;
  }

  svg {
    width: 7px;
    height: 7px;
    color: #fff;
  }
}
