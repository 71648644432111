@import "../../assets/scss/variables.scss";

.call-item {
  display: flex;
  align-items: center;
  min-height: 40px;
  max-width: 250px;
  position: relative;
  padding-right: 10px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover &__btn--call {
    opacity: 1;
  }

  &--active {
    min-width: 297px;
    transform: translate(0);
    z-index: 6;
    margin-bottom: 0;
  
    .call-item__main {
      padding: 0;
      min-width: 290px;
      background-color: $color-white;
    }
  
    .call-item__number {
      font-size: 14px;
    }
    .call-item__timers {
      height: 40px;
      min-height: 19px;
    }
  }

  &__spinner-wrap {
    height: 18px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    img {
      margin-left: 5px;
    }
  }

  &--alarm &__main,
  &--transfer &__main {
    border-radius: 5px;
    animation: blink 1s linear infinite;
    background-color: $color-mint-talib;
  }

  &__main {
    display: flex;
    flex-grow: 1;
    align-items: center;
    transition: all 1s;
    min-width: 0;
    max-width: 250px;
    max-height: 40px;
    // margin-right: 5px;
    position: relative;
    // background-color: #f5f2e1;
    background-color: $color-zanah;
    border-radius: 5px;
    padding: 5px 8px 5px 5px;
  }
  &__img-wrap {
    width: 32px;
    height: 32px;
    margin-right: 5px;
    cursor: pointer;
  }
  &__content-wrap {
    display: flex;
    flex-grow: 1;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__img {
    border-radius: 50%;
  }
  &__info {
    display: flex;  
    align-items: center;
    flex-grow: 1; // this means that the div will grow in same proportion as the window-size
    flex-shrink: 1; // this means that the div will shrink in same proportion as the window-size
    flex-basis: 0; // this means that the div does not have a starting value as such and will take up screen as per the screen size available for.e.g:- if 3 divs are in the wrapper then each div will take 33%.
    min-width: 0;
    text-align: left;

    &--missed {
      margin-right: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &--active {
      min-width: 297px;
      height: 46px;
      display: flex;
      align-items: center;
      background-color: $color-bermuda;
      border-radius: 6px;
      padding: 0 10px;
      & p {
        display: flex;
        // align-items: center;
        // justify-content: center;
        color: $color-text-primary;
        margin-left: 10px;
      }
    }
    &--outgoing-ringing {
      background-color: mix($color-ripe-lemon, #fff, 30%);
    }
  }
  &__duration {
    font-style: italic;
    text-align: right;
    color: $color-tundora;

    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding-right: 20px;
    font-size: 14px;
  }
  &__number {
    display: flex;
    flex-direction: column;
    color: $color-text-primary-light;

    margin: 0;
    line-height: 1.5;
    font-size: 12px;
    // color: #808080;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__arrow-rigth {
    margin: 0 5px;
  }
  &__arrow-transfer {
    display: flex;
    text-align: center;
    align-items: center;
    font-style: italic;
  }
  &__sender-name {
    min-width: 40px;
    color: $color-green-haze;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }
  &__recipient-name {
    min-width: 40px;
    color: $color-azure-radiance;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }
  &__hold-muted-call {
    display: flex;
    align-items: center;
  }
  &__hold-muted-icon {
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: 3px;
  }
  &__micro-icon {
    position: relative;
    display: inline-block;
    margin-left: 5px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: inline-block;
      height: 14px;
      width: 2px;
      right: 5px;
      background-color: $color-red;
    }

    &::before {
      transform: rotate(135deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }
  &__outgoing-msg {
    font-weight: 100;
    font-size: 18px;
    color: #000;
  }
  &__timers {
    font-style: italic;
    text-align: right;
    color: $color-tundora;
    font-size: 14px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    height: 35px;
    min-height: 13px;

    &--pause {
      flex-direction: column;
    }
  }
  &__timer {
    margin-right: 10px;
    color: $color-text-primary-darkest;
    overflow: hidden;
    text-overflow: ellipsis;

    &--hold {
      color: $color-red;
    }

    &--active {
      font-style: normal;
      font-weight: 200;
      font-size: 18px;
      line-height: 21px;
      &-hold {
        color: $color-red;
      }
    }
  }
  &__btns {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 17px;
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-right: 18px;
    width: 20px;
    height: 20px;

    &--hang-up {
      margin-right: 0;
    }

    &--call {
      display: inline-flex;
      opacity: 0;
      transition: 0.1s all ease-out;
    }
  }

  &__transfer-form {
    position: absolute;
    z-index: 6;
    top: 30px;
    left: -170px;
  }
}

.call-item--incoming {
  .call-item__main {
    // border: 1px solid #40e49e;
    border: 1px solid $color-border-focus;
    background-color: $color-white;
  }
  .call-item__btn--call {
    opacity: 1;
    padding: 0;
    margin-left: 15px;
    // &:disabled svg {
    //   fill: #a5a5a5;
    // }
  }
}

.call-item + .player {
  position: relative;
  border-radius: 0 0 6px 6px;
  top: -7px;
  width: calc(100% - 23px);
  border-bottom: solid 1px $color-border-primary;
  border-left: solid 1px $color-border-primary;
  border-right: solid 1px $color-border-primary;

  &::after {
    content: "";
    height: 1px;
    width: calc(100% - 10px);
    background-color: $color-azure-radiance;
    display: block;
    position: absolute;
    left: 5px;
    top: 0;
  }
}

@keyframes blink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.96);
  }
  0% {
    transform: scale(1);
  }
}

.call-transfer {
  background: $color-white;
  width: 250px;
  box-shadow: 0px 2px 10px #999999;
  padding: 10px;
  font-size: 13px;

  &__title {
    margin-top: 0;
  }
  &__list {
    padding: 0 0 0 15px;
    color: $color-text-primary-darkest;
  }
  &__description {
    width: 200px;
    height: 60px;
    padding: 5px;
  }
  &__footer {
    text-align: center;
  }
  &__btn {
    margin: 0 5px;
  }
}
