@import 'assets/scss/variables';

.search-input {
  position: relative;

  &:focus-within {
    .search-input__btn {
      color: $color-border-focus;
    }
  }

  &__input {
    padding-right: 37px;
  }

  &__btn {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 20px;
    height: 20px;
    color: $color-silver;
    transition: color 0.15s ease-in-out;

    &--clear {
      cursor: pointer;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    color: $color-bondi-blue;

    &--clear {
      width: 12px;
      height: 12px;
      color: $color-red-orange;
    }
  }
}