@import 'assets/scss/variables.scss';

.sidebar-sections {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    margin-bottom: 10px;
    padding: 0 4px 0 1.5px;
  }

  &__list {
    flex-grow: 1;
    margin-left: -4px;
    margin-right: -9px;
  }
}

.search-contacts {
  height: 100%;
  overflow: hidden;

  &__load-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__drop-wrap {
    width: 240px;
    position: absolute;
    height: 460px;
    left: 240px;
    top: 160px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 1px #0000003b;
    z-index: 99;
    position: fixed;
    border: solid 1px #0092f2;
    &--girls {
      left: auto;
      right: 240px;
    }
  }

  &__wrap, &__list-container  {
    height: 100%;
  }

  &__list-wrap {
    height: calc(100% - 43px);
  }

  &__list {
    padding: 0 2px;
    margin: 5px 0;
  }

  &__no-items{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 14px;
    color: #bfbfbf;
  }

  &__item {
    display: flex;
    margin: 3px 0;
    cursor: pointer;
    // background-color: rgba(238, 238, 238, 0.363);

  }

  &__input-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    border-bottom: solid 1px #0092f2;
  }

  &__search-input {
    border-radius: 4px;
    border: solid 1px #bdbdbd;
    padding: 4px 12px;
    width: calc(100% - 20px);
    outline: none;
    margin: 2px 0;
  }

  &__name {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    width: 170px;

    & span {
      text-overflow: ellipsis;
      overflow: hidden;

      &:nth-child(2) {
        color: #808080;
        font-size: 13px;
      }
    }
  }
  .contact-item__img-wrap {
    border-radius: 50%;
    overflow: hidden;
    height: 40px;
    width: 40px;
    border: solid 1px #0092f2;
  }
}
