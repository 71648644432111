.date-time-picker {
  padding: 16px 10px 24px;
  width: 390px;

  border-radius: 6px;
  border-bottom: 2px solid #00A9AF;
  background-color: #fff;
  box-shadow: 0 16px 16px 0 rgba(0,0,0,0.08);

  .default-calendar {
    margin: 0 auto 5px;
  }

  .time-select {
    margin-bottom: 18px;
  }

  &__actions{
    text-align: center;
  }

  &__cancel, &__submit{
    padding: 6px;
    min-width: 100px;
    border-radius: 6px;

    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #fff;
  }

  &__cancel{
    padding: 6px 11px;
    background-color: #bfbfbf;
  }

  &__submit{
    padding: 11px;
    margin-left: 30px;
    background-color: #00A6AD;
    box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);
  }
}
