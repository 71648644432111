@import 'assets/scss/variables';

.navbar {
  border-radius: 6px;
  background-color: $color-mystic;

  &__list {
    display: flex;
  }

  &__item {
    overflow: hidden;
    width: 50px;
    height: 50px;
   
    &:first-child {
      border-radius: 6px 0 0 6px;
    }
    &:last-child {
      border-radius: 0 6px 6px 0;
    }

    &--calendar {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: $color-tundora;
    border-radius: 6px;

    &--active {
      background-color: $color-bermuda;
      color: $color-white;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
  }
}