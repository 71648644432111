@import '../../../../assets/scss/variables.scss';

.adr-book-item {
  display: flex;
  position: relative;
  align-items: center;
  width: calc(100% - 7px) !important;
  height: 38px !important;
  margin-bottom: 12px;
  padding: 3px 4px;
  border-radius: 6px;
  cursor: pointer;
  background-color: $color-white;

  &--active {
    background-color: rgba(136, 214, 217, 0.3);
  }

  &__name {
    width: 100%;
    max-width: 295px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__contact-status {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: $color-froly;
    &--active {
      background-color: $color-apple;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-top: 3px;
    color: $color-gray;
    font-weight: 500;
    font-size: 12px;
  }

  &__link-wrap {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    margin-right: 14px;
  }

  &__img-wrap {
    display: flex;
    justify-content: space-between;
    padding-right: 5px;

    svg {
      margin-top: 5px;
      color: $color-black;
    }

    &--off-today svg {
      color: $color-silver;
    }

    &--available svg {
      color: $color-apple;
    }
    
  }

  &__external-link {
    display: flex;
    color: $color-gray;
  }

  .lazy-load__container {
    width: 32px;
    height: 32px;
    overflow: hidden;
    background: transparent;
  }
}