@import 'assets/scss/variables';

.chat-header-action-menu {
  $this: &;

  position: relative;
  padding: 8px 11px;
  transition: all linear .1s;

  &--active {
    background-color: #fff;
    box-shadow: $shadow-primary;
  }

  &--active &__menu-btn {
    color: $color-bondi-blue;
  }

  &__menu-btn {
    display: flex;
    padding: 0;
    color: $color-gray;
  }

  &__clip-wrapper {
    clip-path: inset(0 -200px -200px -200px);
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  &__list {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translateY(-100%);
    transition: all ease-out .15s;
    width: 100%;
    border-radius: 0 0 6px 6px;
    box-shadow: $shadow-primary;

    &--visible {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 36px;
    background-color: #fff;
    cursor: pointer;
    transition: background-color linear .1s;
    
    &:not(:disabled):hover {
      background-color: $color-bermuda;
    }

    #{$this}__list-item:last-child & {
      border-radius: 0 0 6px 6px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__icon {
    width: 14px;
    height: 14px;
    color: $color-gray;
    transition: color linear .1s, background-color linear .1s;

    #{$this}__btn:disabled & {
      opacity: .3;
    }

    &--taken {
      color: $color-bondi-blue;
    }

    &--pin {
      transform: rotate(0deg);
      transition: transform 0.2s ease;
    }
    &--pinned {
      color: $color-bondi-blue;
      transform: rotate(45deg);
    }

    &--mark {
      width: 12px;
      height: 12px;
      background-color: $color-gray;
      border-bottom-left-radius: 4px;
      clip-path: polygon(100% 100%, 0% 0%, 0% 100%);
    }
    &--marked  {
      background-color: $color-froly;
    }
  }
}