@import 'assets/scss/variables.scss';

$ava-size: 90px;

.adr-book-contact-form {
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  height: 1px;

  &-wrap {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-top: 6px;
  }

  &__avatar-wrapper {
    display: flex;
    margin-right: 30px;
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    text-align: center;
  }

  &__mail {
    position: relative;
    margin-bottom: 22px;
  }

  &__input--error {
    border: 1px solid $color-red !important;
  }

  &__tel {
    position: relative;
    &:not(:first-child) {
      margin-top: 22px;
    }
  }

  &__input-field-btn {
    position: absolute;
    top: 50%;
    padding: 0;
    transform: translateY(-50%);

    &--field-action {
      display: flex;
      right: 48px;
      color: $color-bondi-blue;
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &--del {
      display: flex;
      z-index: 1;
      right: 8px;
      justify-content: center;
      align-items: center;
      padding: 5px;
      color: $color-persimmon;
      svg {
        width: 8px;
        height: 8px;
      }
    }

    &--action { //TODO remove
      position: relative;
      display: flex;
      margin: 10px 20px;
      font-size: 14px;
      font: 14px Roboto;
      padding: 10px 20px;
      background-color: $color-bondi-blue;
      border-radius: 6px;
      letter-spacing: 0.88px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12);
      color: #fff;

      &:disabled {
        cursor: not-allowed;
        
        svg {
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }


  &__actions { //TODO remove
    display: flex;
    justify-content: center;
    margin: 20px 0;
    text-align: center;
  }

  &__add-field-btn {
    position: absolute;
    padding: 0;
    
    &--tel-add, &--mail-add, &--contact-add {
      display: flex;
      position: relative;
      align-items: center;
      margin-left: 6px;
      color: $color-gray;
              
      &::after {
        display: block;
        position: absolute;
        left: 27px;
        width: 110px;
        font-size: 14px;
        text-align: left;
        content: "Add Number";
      }
    }

    &--tel-add {
      margin-top: 21px;
    }

    &--contact-add {
      margin-top: 9px;
      margin-left: 6px;
      &::after {
        content: "Add Contact";
      }
    }

    &--mail-add::after {
      content: "Add Email";
    }
  }

  &__btn {
    position: absolute;
    padding: 0;

    &-wrapper {
      flex-basis: 100%;
    }

    &-add-icon {
      width: 20px;
      height: 20px;
      color: $color-bondi-blue;
    }

    &-clear {
      display: flex;
      z-index: 2;
      position: absolute;
      top: 11px;
      right: 5px;
      padding: 6px;

      &-icon {
        width: 5px;
        height: 5px;
      }
    }
  }

  &__phone-numbers, &__mails {
    margin-top: 14px;
  }

  &__not-for-item {
    display: flex;
    align-items: center;
    margin: 0 2px;
    margin-bottom: 5px;
    padding: 3px 5px;
    border-radius: 6px;
    color: $color-black;
    font-weight: 300;
    font-size: 12px;
    text-align: left;
    letter-spacing: 1px;
    background-color: $color-mystic;
    
    &-btn {
      display: flex;
      margin-left: 3px;
      padding: 3px;
      color: $color-tundora;
    }

    &-icon {
      width: 8px;
      height: 8px;
    }
  }

  &__select-tel-label {
    margin-top: 4px;
    border: 1px solid transparent;
    border-radius: 6px;
    background-color: $color-gray;
  }

  &__tel-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__input-wrapper {
    display: flex;
    position: relative;
    flex-grow: 1;
    height: 40px;
    margin-left: 15px;
    &--email {
      margin: 0;
    }
  }

  & .form-error-text__wrap {
    position: absolute;
    right: 0;
    bottom: -15px;
    left: 0;
    margin: auto;
    color: $color-red;
    font-weight: 100;
    font-size: 11px;
    text-align: center;
  }

  &__field {
    &--phone-numbers, &--email {
      margin: 26px 0 0;
    }

    &--tags .tags-field {
      margin-top: 5px;
      &-container {
        margin-top: 5px;
        border: 1px solid $color-mystic;
        border-radius: 6px;
      }

      &__control {
        transition: all 0.15s ease-in-out;
        &:focus-within {
          box-shadow: 0 0 0 1px $color-border-focus;
        }
      }
    }

    &--via {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 26px;
    }

    &--not-for {
      display: flex;
      flex-wrap: wrap;
      margin-top: 36px;
    }

    &--agent {
      margin-left: 16px;
      .react-select__menu {
        left: -55px;
        width: 250px;
      }
    }

    &--contact-type, &--agent {
      display: flex;
      align-items: center;
      width: 204px;
      margin-bottom: 0 !important;
      padding: 4px 5px 4px 9px;
      border: 1px solid $color-mystic;
      border-radius: 6px;
      color: $color-gray;
      font-size: 14px;
      transition: all 0.15s ease-in-out;
      &:focus-within {
        border-color: $color-border-focus;
      }
    }

    &--phone-numbers {
      & .tags-field:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &--uid {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 16px;
    }

    &-content {
      align-self: center;
      margin-left: 5px;
      font-size: 12px;
      &--uid {
        display: flex;
        overflow: hidden;
        color: $color-black;
        text-overflow: ellipsis;
        white-space: nowrap;
    
        .clipboard-btn__icon {
          width: 14px;
          height: 14px;
          margin-left: 19px;
          color: $color-bondi-blue;
        }
      }
    }
  }

  &__field-title {
    padding: 0 12px;
    color: $color-gray;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    &--uid {
      min-width: 83px;
    }
  }

  &__header {
    display: flex;
    padding-bottom: 13px;
    &-content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;

      &-wrapper {
        display: flex;

        .react-select {
          flex-grow: 1;
          margin-left: 5px;
          font-size: 14px;
          $block: '.react-select';
      
          #{$block}__control {
            padding: 0;
            cursor: pointer;
          }

          #{$block}__dropdown-indicator {
            padding: 0 3px;
            color: $color-gray;
          }
      
          #{$block}__single-value {
            color: $color-dodger-blue;
            font-weight: normal;
          }
      
          #{$block}__indicator-separator {
            display: none;
          }
      
          #{$block}__option {
            font-size: 12px;
          }
        }
      }
    }
  }

  &__fn-wrapper {
    position: relative;
    width: 100%;
  }

  &__input-wrap {
    display: flex;
    margin-top: 11px;

    &--is-contact-girl {
      flex-direction: row-reverse;
    }
    
    &--address {
      margin-top: 1px;
      padding: 25px 17px;
    }
  }

  &__input {
    width: 100%;
    padding: 12px 72px 12px 10px;
    border: 1px solid $color-mystic;
    border-radius: 6px;
    outline: none;
    color: $color-tundora;
    font-size: 14px;
    line-height: 19px;
    font-family: Roboto;
    letter-spacing: 0;
    transition: all 0.15s ease-in-out;

    &--name {
      padding: 0;
      border: none;
      .input {
        border: 1px solid $color-mystic;
        &:focus-visible {
          border-color: $color-border-focus;
        }
      }
    }

    &--id {
      width: 164px;
      margin-right: 12px;
      padding: 11px 12px 8px 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: none;
    }

    &--is-contact-girl {
      margin-right: 0;
      margin-left: 30px;
    }

    &--address {
      padding: 5px 35px 5px 5px;
      border: 1px solid $color-silver;
      border-radius: 6px;
      background-color: $color-white;

      & textarea {
        display: block;
        max-height: 166px;
        padding: 10px 5px 10px 10px;
        border: none;
        color: $color-tundora;
        font-size: 14px;
        line-height: 19px;
        font-family: Roboto;
        letter-spacing: 0.88px;
        resize: none;
        @include scrollBars;
      }
    }

    &:focus-visible {
      border-color: $color-border-focus;
    }
  }

  &__date-status {
    flex-grow: 1;
    color: $color-tundora-light;
    font-size: 10px;
    line-height: 13px;
    text-align: right;
    letter-spacing: 0.4px;
  }

  &__form {
    width: 50%;
    min-width: 345px;
    padding: 0 13px 25px 0;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px; 
    }

    &::-webkit-scrollbar-thumb {
      margin: 5px;
      border-radius: 3px;
      background: $color-silver;
    }
  }

  &__info {
    width: 50%;
    min-width: 410px;
    margin-left: 14px;

    .react-tabs {
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: $color-mystic;

      &__tab {
        margin: 0 3px;
        padding: 6px 10px 8px;
        border-radius: 6px 6px 0 0;
        color: $color-tundora;
        font-size: 12px;
        line-height: 16px;
        font-family: Roboto;
        letter-spacing: 0;
        cursor: pointer;
        background-color: $color-mystic;
        transition: all linear 0.1s;

        &-list {
          display: flex;
          padding-bottom: 1px;
          background-color: $color-white;
        }

        &-panel--selected {
          display: flex;
          flex-direction: column;
          height: 100%;
          overflow-y: auto;
          @include scrollBars;
          
          &::-webkit-scrollbar {
            width: 4px;
          }

          &::-webkit-scrollbar-thumb {
            background: $color-silver;
          }
        }

        &--selected {
          color: $color-white;
          font-weight: 500;
          background-color: $color-bondi-blue;
        }
      }
    }
  }

  &__feedbacks-tab {
    height: 100%;
    padding: 12px 0 0 16px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  &__content {
    display: flex;
    height: 100%;
    overflow-y: hidden;
  }

  .lazy-load__container {
    width: 90px;
    height: 90px;
    overflow: hidden;
    margin-right: 31px;
  }

  .tags-field {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    padding: 8px 4px;
    border: 1px solid $color-mystic;
    border-radius: 6px;
  
    &__tag {
      margin: 3px;
      padding: 2px 5px;
      border-radius: 6px;
      color: $color-tundora-light;
      font-size: 11px;
      line-height: 15px;
      background-color: $color-athens-gray;
    }
  
    &__input {
      display: block;
      flex-grow: 1;
      padding: 0 5px;
      border: none;
      outline: none;
      color: $color-tundora;
      font-size: 14px;
      font-family: Roboto;
      letter-spacing: 0.88px;
    }
  
    &__btn {
      display: flex;
      align-items: center;
      padding: 3px 6px;
      color: $color-gray;
    }
  }

  &-lock {
    &__group {
      display: flex;
      align-items: center;
      margin: 38px 13px 26px;
      line-height: 1.5;
      text-align: left;

      & .checkbox__indicator {
        width: 16px;
        height: 16px;
      }

      & .checkbox {
        align-self: center;
      }
  
      & .lock-form {
        &__name {
          max-width: 72px;
          margin-left: 6px;
          overflow: hidden;
          font-weight: normal;
          white-space: nowrap;
        }
  
        &__block-send-media-toggle-label {
          display: flex;
          cursor: pointer;
        }
  
        &__block-send-media-toggle-span {
          width: auto;
          margin-left: 8px;
          color: $color-black;
          font-size: 12px;
          text-transform: capitalize;
        }
      }
    }
  
    &__label {
      color: $color-gray;
      font-size: 14px;
      letter-spacing: 0;
    }
  }

  &__map-icon-wrap {
    position: absolute;
    top: calc(50% + 5px);
    right: 30px;
    color: $color-gray;
  }

  &__icon-action {
    color: $color-bondi-blue;
  }

  .adr-contact-ava {
    &__img-container {
      display: flex;
      border-radius: 50%;

      &:hover .adr-contact-ava__control-icons {
        background-color: rgba($color-black, 0.8);
      }

      &:hover .adr-contact-ava__icon {
        opacity: 1;
      }
    }

    &__img-wrap {
      width: $ava-size;
      height: $ava-size;
      border-radius: 50%;
    }

    &__img {
      width: $ava-size;
      height: $ava-size;
      border-radius: 50%;
      background-color: $color-white;
    }

    &__control-icons {
      display: flex;
      position: absolute;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      width: $ava-size;
      height: $ava-size;
      border-radius: 50%;
      background-color: rgba($color-black, 0);
      transition: opacity 0.2s linear, background-color 0.2s linear;
      z-index: 5;
    }

    &__icon-wrapper {
      &--upload {
        padding: 3.5px;
      }

      &--delete {
        padding: 2.5px;
      }

      &--edit {
        padding: 5.5px;
      }
    }

    &__icon {
      opacity: 0;
      color: $color-white;
      transition: opacity 0.2s linear, background-color 0.2s linear;
      cursor: pointer;

      &--upload {
        font-size: 20px;
      }

      &--delete {
        width: 22px;
        font-size: 22px;
      }

      &--edit {
        font-size: 16px;
      }
    }

    &__image-input {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      outline: none;
      cursor: pointer;
    }

    .adr-book-img-cropper {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 999;

      .bg-dark {
        animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      }

      &__ava-cropper-wrap {
        position: relative;
        top: -60px;
        background-color: rgba(209, 209, 209, 1);
        animation: translateUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      }

      &.close {
        .bg-dark {
          animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        }

        .adr-book-img-cropper__ava-cropper-wrap {
          animation: translateDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        }
      }

      &__ava-cropper {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 400px;
        height: 400px;
      }

      &__ava-cropper img {
        width: inherit;
        height: inherit;
        object-fit: unset;
        min-width: unset;
      }

      &__ava-cropper-section {
        position: relative;
        width: 100%;
        height: 100%;
      }

      &__ava-cropper-navigation-bar {
        width: 100%;
        background-color: $color-mine-shaft;
        height: 50px;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .ava-cropper-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 28px;
        margin: 0 5px;
        padding: 0;
        border-radius: 6px;
        color: $color-white;
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
        transition: 0.14s all;

        &:disabled {
          background-color: $color-alto;
          color: $color-silver;
          cursor: not-allowed;
        }

        &:hover {
          background-color: $color-silver;
        }
      }

      &__ava-cropper-btn-input-file {
        display: block;
        opacity: 0;
        visibility: hidden;
        width: 0;
        height: 0;
      }

      &__ava-cropper-btn-file-add {
        background-color: $color-azure-radiance;
      }

      &__ava-cropper-btn-file-crop {
        background-color: $color-persimmon;
      }

      &__ava-cropper-btn-file-submit {
        background-color: $color-caribian-green;
      }

      &__ava-cropper-options-bar {
        display: flex;
        position: absolute;
        bottom: 60px;
        align-items: center;
        width: calc(100% - 40px);
        margin: 0 20px;

        .toggle__wrap {
          margin: 0 0 0 10px;
          padding: 4px 10px;
          border-radius: 6px;
          background-color: rgba(0, 0, 0, 0.4);

          .toggle__title {
            color: $color-white;
          }
        }
      }

      @keyframes translateUp {
        0% {
          opacity: 0;
          transform: translateY(1000px);
        }

        100% {
          opacity: 1;
          transform: translateY(0px);
        }
      }

      @keyframes translateDown {
        0% {
          opacity: 1;
          transform: translateY(0px);
        }

        100% {
          opacity: 0;
          transform: translateY(1000px);
        }
      }
    }
  }
}

.adr-book-img-cropper {
  display: flex;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

  &.close {
    animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }

  &__toggle-title {
    display: block;
    text-align: center;
  }
}

@keyframes fadeIn {
  0% {
    background: rgba(0, 0, 0, 0);
  }
  100% {
    background: rgba(0, 0, 0, 0.7);
  }
}
@keyframes fadeOut {
  0% {
    background: rgba(0, 0, 0, 0.7);
  }
  100% {
    background: rgba(0, 0, 0, 0);
  }
}