@import 'assets/scss/variables';

.sidebar-contacts-filters {
  position: relative;

  &__input-wrap {
    position: relative;

    .search-input {
      &__icon--clear {
        width: 6px;
        height: 6px;
        color: #FF3D2B;
      }
    }
  }

  &__search-input {
    padding-right: 70px !important;
  }
  
  &__toggle-btn {
    position: absolute;
    right: 38px;
    top: 8px;
  }
  &__toggle-btn-icon-wrap {
    position: absolute;
    right: 6px;
    top: 6px;
    background-color: $color-mystic;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    color: #fff;
    font-size: 12px;

    &--active {
      top: 0px;
      right: 0px;
      background-color: $color-bondi-blue;
    }
  }
}

.sidebar-filter-select {
  display: flex;
  align-items: center;
  margin-top: 11px;
  margin-bottom: 11px;

  .react-select {
    width: 100%;

    &__control {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      padding-left: 0;
      height: 32px;
      border-radius: 6px;

      &--menu-is-open {
        background-color: $color-athens-gray;
      }
    }
    &__menu {
      min-width: 200px;
      max-width: 240px;
      left: 50%;
      transform: translateX(-50%);

      &-list {
        max-height: 100%;
      }
    }
    &__single-value {
      font-weight: bold;
    }
    &__indicator {
      padding: 0 10px;
    }
  }
}