@import '../../assets/scss/variables.scss';

.adr-book-tags {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 267px;
  color: $color-gray;
  font-size: 14px;

  &__list {
    flex-grow: 1;
    height: 1px;
    margin-top: 21px;
    margin-left: 12px;
    padding-right: 5px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-button {
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: $color-silver;
      border-radius: 3px;
      margin: 5px;
    }
  }

  &__list-item {
    margin: 5px 0;
  }

  &__spinner-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 245px;
    height: calc(100% - 16px);
  }

  &__title {
    text-transform: capitalize;
  }

  &__tag {
    display: flex;
    width: 246px;
    padding: 10px 20px;
    padding: 7px 20px;
    border-radius: 5px;
    color: $color-black;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.88px;

    &-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--active {
      background-color: rgba(0, 166, 173, 0.3);
    }
  }
}