@import 'assets/scss/variables.scss';

.search-list-client-item {
  padding: 5px;
  display: flex;
  column-gap: 10px;

  background-color: #F6F9FC;
  border-radius: 6px;
  cursor: pointer;

  &__avatar-thumb {
    width: 36px;
    height: 36px;
    flex-shrink: 0;

    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;

    img {
      display: block;
    }
  }

  &__info {
    padding: 1px 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__name {
    @include webkit-text-overflow;

    color: #3565FD;
    font-size: 12px;
  }

  &__mail-or-phone {
    @include webkit-text-overflow;

    color: #808080;
    font-size: 12px;
  }
}
