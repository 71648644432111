@import 'assets/scss/variables';

.chat-input {
  padding-top: 2px;
  
  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 12px;
    height: 42px;
  }

  &__indicator {
    display: flex;
    font-size: 13px;
    align-items: flex-end;
    width: 50%;
  }

  &__dispatch-types {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
  }
  &__dispatch-type-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    border-top: 2px solid transparent;
    color: rgba(0, 0, 0, 0.5);
    
    &--active{
      background-color: #fff;
      border-color: $color-bondi-blue;
      box-shadow: 0 1px 2px 0px #88D6D9;
      color: $color-bondi-blue;

      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        width: 100%;
        height: 1px;
        background-color: #fff;
        z-index: 1;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    padding-left: 5px;
  }
  &__btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin: 0 5px;
    padding: 0;

    &--template {
      &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        width: 100%;
        height: 10px;
        // background-color: red;
      }
    }

    &--emoji {
      &:hover svg > g {
        fill: #0092f2;
      }
    }

    &-icon {
      width: 14px;
      height: 14px;
      color: $color-silver;

      &--voice-record {
        width: 15px;
        height: 15px;
      }
    }
  }

  &__template-list-wrap {
    position: absolute;
    z-index: 10;
    bottom: calc(100% + 5px);
    z-index: 10;

    &:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 5px;
    }
  }
  &__template-list {
    overflow: auto;
    width: 240px;
    max-height: 140px;
    background-color: #fff;
  }

  &__form {
    padding: 0 10px 0 7px;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #E6EBF1;
    border-radius: 6px;
    background: #fff;
    margin: 0 10px 10px;

    &:focus-within {
      border: 1px solid $color-border-focus;
    }
  }
  &__input {
    flex-grow: 1;
    margin: 15px 5px 15px 0;
    padding: 0px 5px;
    outline: none;
    border: none;
    font-size: 14px;
    background: none;
    color: $color-tundora-light;
    resize: none;
    max-height: 250px;
    line-height: 1.2;
    border-radius: 5px;
    background-color: transparent;
    transition: background-color linear 1s;
    font-family: inherit;

    &--changed {
      background-color: #cce9fd;
      transition: background-color linear .05s;
    }

    &:disabled {
      filter: blur(1px);
      cursor: not-allowed;
    }

    &:focus > .chat-input__btn svg {
      fill: #01df85;
    }
    &::placeholder {
      line-height: 50px;
      font: 14px sans-serif;
      color: $color-silver;
      opacity: 1;
      letter-spacing: 0.88px;
      // position: absolute;
      // top: 50%;
      // transform: translateY(-50%);
    }
  }

  &__send-btn {
    display: flex;
    padding: 2px;
    color: $color-gray;

    &-icon {
      width: 12px;
      height: 12px;
    }
  }

  &__shortcut {
    position: absolute;
    cursor: pointer;
    font-size: 13px;
    display: inline-block;
    padding: 2px 5px;
    background-color: #0092f2;
    color: #fff;
    border-radius: 3px;
    z-index: 3;
    overflow: hidden;
    width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      color: #0092f2;
      background-color: #fff;
      box-shadow: 0px 2px 10px #999999;
    }
  }

  &__mention-list {
    position: absolute;
    bottom: 50px;
    z-index: 3;

    border: 1px solid green;
    list-style: none;
    padding: 10px 15px;
    margin: 0;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
    background-color: #F6F9FC;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    max-height: 312px;
    width: 100%;

    overflow-y: auto;
  }

  &__indicator {
    display: flex;
    font-size: 13px;
    align-items: flex-end;
    padding-left: 4px;
  }

  &__indicator-text {
    color: #bdbdbd;
    font-size: 11px;
    white-space: nowrap;
  }
}

.message-forbid {
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  font-family: Roboto;

  & ~ .chat-input__form,
  & ~ .chat-input__header {
    display: none;
    // position: fixed;
    // bottom: -50vh;
  }

  &__input {
    cursor: pointer;
    background-color: $color-white;
    border: 1px solid $color-red;
    border-radius: 6px;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
    color: $color-red;
    margin: 0 10px 9px;
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 12px;
  }

  &__text {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.88px;
    color: $color-red;
  }
}

.mention {
  display: flex;
  align-items: center;
  padding: 3px;
  cursor: pointer;
  color: #858181;
  font-size: 12px;
  background-color: #e6ebf0;
  margin-bottom: 5px;
  border-radius: 5px;

  &--active {
    background-color: #0092f2;
    color: #fff;
  }

  &:hover {
    background-color: #0091f286;
    color: #fff;
  }

  &__ava-wrap {
    width: 32px;
    min-width: 32px;
    height: 32px;
    position: relative;
    background-color: #ffffff;
    border-radius: 50%;
  }
  &__ava {
    border-radius: 50%;
  }
  &__status {
    width: 8px;
    height: 8px;
    position: absolute;
    border-radius: 50%;
    top: -3px;
    left: 0;
    background-color: red;
    z-index: 2;
    border: 1px solid rgb(230, 235, 241);
  }
  &__name {
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.emoji-mart {
  position: absolute;
  bottom: 29px;
  right: -12px;
  width: 250px !important;
  z-index: 6;
}
.emoji-mart-preview {
  display: none;
}

.voice-message {
  &__record {
    width: 28px;
    height: 25px;
    display: flex;

    &:disabled {
      opacity: .4;
      cursor: default;
    }

    svg {
      z-index: 2;
    }
  }
  &__record-wrap {
    position: relative;
  }
  &__record-bg {
    position: absolute;
    width: 26px;
    height: 25px;
    background-color: #0092f2;
    border-radius: 100%;
    z-index: 2;
    left: 1px;
    top: 0px;
    animation: startRecord 4s infinite, initRecord 0.5s;
  }
  &__info {
    position: absolute;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 40px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    padding: 4px;
    padding-right: 40px;
    top: 0px;
    right: 0px;
    font-size: 14px;
    animation: initRecordInfo 0.5s;
    overflow: hidden;
    z-index: 1;
  }
  &__time {
    display: flex;
    align-items: center;
  }
  &__indicator {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #dc3545;
    animation: indicator 1s infinite;
    margin: 0px 5px;
  }
  &__send {
    padding: 0;
    margin: 0;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &::before {
      content: "";
      display: none;
      position: absolute;
      width: 22px;
      height: 22px;
      top: -2;
      left: -4px;
      border-radius: 100%;
      background: rgba(0, 146, 242, 0.2);
    }

    &:hover::before {
      display: block;
    }
  }
  &__stop {
    color: white;
    background-color: #dc3545;
    width: 12px;
    height: 12px;
    margin-left: 10px;
    position: relative;

    &::before {
      content: "";
      display: none;
      position: absolute;
      width: 22px;
      height: 22px;
      top: -5px;
      left: -5px;
      border-radius: 100%;
      background: rgba(220, 53, 69, 0.2);
    }

    &:hover::before {
      display: block;
    }
  }
}

@keyframes startRecord {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1.5, 1.5);
  }
  50% {
    transform: scale(1, 1);
  }
  75% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes initRecord {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes indicator {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes initRecordInfo {
  0% {
    opacity: 0;
    width: 0px;
  }
  99% {
    width: 119px;
  }
  100% {
    width: auto;
    opacity: 1;
  }
}

.custom-scroll {
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-button {
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin: 5px;
  }
}
