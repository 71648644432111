.icon-menu {
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	&:disabled {
		cursor: default;
	}
}

.icon-menu-popup {
	z-index: 100;

  width: max-content;
  min-width: 150px;

  border-radius: 6px;
}
