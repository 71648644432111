@import './variables.scss';

.btn {
  @include btnInteraction($color-silver);

  user-select: none;
  position: relative;
  display: inline-block;
  padding: 5px 16px;
  border-radius: 6px;
  line-height: 20px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  color: $color-white;
  background-color: $color-silver;
  border-color: $color-border-primary;
  box-shadow: $shadow-primary;

  &:disabled {
    cursor: default;
  }

  &--disabled {
    opacity: 0.3;
  }
}

.btn--primary {
  @include btnInteraction($color-bondi-blue);

  color: $color-white;
  box-shadow: $shadow-primary;
  background-color: $color-bondi-blue;
}