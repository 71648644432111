@import 'assets/scss/variables';

.gallery-header-filters {
  margin-top: 1px;             
  background-color: $color-mystic;

  &__includes-filters-text {
    margin-right: 6px;
  }

  &__include-filter {
    margin-right: 28px;
  }

  &__divider {
    width: 100%;
    height: 1px;
    background-color: $color-gray;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 0 15px 0 30px;
    height: 45px;
    font-size: 14px;
    color: $color-gray;
  }

  &__main {
    padding-bottom: 17px;
    border-bottom: 1px solid $color-bondi-blue;
  }

  &__group {
    padding: 8px 10px;
    display: grid;
    grid-template-columns: auto;

    &--row-1 {
      grid-template-columns: 1fr 2fr;
    }
    &--row-2 {
      grid-template-columns: 1fr 1fr;
    }
    &--row-3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
    &--row-4 {
      grid-template-columns: 1fr 1fr 1fr;
    }
    &--row-5 {
      grid-template-columns: 1fr 2fr;
    }
  }
  
  &__clear-btn {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: 0 8px;
    height: 24px;
    background-color: #fff;
    font-size: 12px;
    border-radius: 6px;

    &-icon {
      margin-right: 8px;
      width: 8px;
      height: 8px;
      color: $color-red-orange;
    }
  }

  &__filter {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }

    &-title {
      display: inline-flex;
      margin-bottom: 7px;
      font-size: 12px;
      letter-spacing: 1px;
    }
  }
}

.gallery-header-include-filter {
  $this: &;

  display: flex;
  align-items: center;
  padding: 4px;
  cursor: pointer;
  
  &__checkbox {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    border: 1.5px solid $color-gray;
    background-color: #fff;
  
    #{$this}--checked & {
      border: none;
    }
  }

  &__icon {
    width: 12.5px;
    height: 12.5px;
    color: $color-caribian-green;
  }

  &__label {
    margin-left: 7px;
    color: #000;
    font-size: 12px;
    text-transform: capitalize;
    transform: translateY(1px);

    #{$this}--checked & {
      font-weight: bold;
    }
  }
}

.gallery-header-filters .react-select {
  &__control {
    min-height: 28px;
    border-radius: 6px;
    background-color: $color-athens-gray;
  }
  &__indicator {
    svg {
      width: 16px;
      height: 16px;
    }
  }
  &__placeholder {
    font-size: 14px;
    color: $color-silver;
    letter-spacing: 0.88px;
  }
  &__dropdown-indicator {
    padding-left: 6px;
    color: $color-gray;
  }
  &__value-container {
    padding: 2px 8px;
  }
  &__menu {
    border-radius: 6px;
    margin-top: 8px;
  }
  &__multi-value {
    border-radius: 6px;
    background-color: #fff;
  }
  &__multi-value__label {
    padding: 2px 3px 2px 8px;
    font-size: 11px;
    color: $color-tundora-light;
  }
  &__value-container {
    padding: 0px 8px;
  }
}
