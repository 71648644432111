@import '../../assets/scss/variables.scss';

.adr-book-list-wrapper {
  display: flex;
  flex-direction: column;
  width: 390px;
  min-width: 390px;
  height: 100%;
  margin: 0 30px;

  &__header {
    display: flex;
    justify-content: flex-end;
    padding: 8px 0;
    color: $color-gray;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
  }

  &__list {
    height: 100%;
    padding: 26px 10px 0;
    background-color: $color-mystic;
  }
}