@import 'assets/scss/variables';

.availability-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 0 6px;
  width: 100%;
  height: 100%;
  background-color: $color-mystic;

  &__pending,
  &__no-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0 6px;
    width: 100%;
    height: 100%;
    background-color: $color-mystic;
    font-size: 14px;
    font-style: italic;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 12px;
    padding-right: 12px;
    border-radius: 6px;
    color: $color-gray;
  }

  &__list {
    padding: 0 3px 0 15px;
    width: 100%;
    height: 100%;
    list-style: none;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0 11px 11px;
  }

  &__title {
    font-size: 12px;
    font-weight: 500;
    color: $color-gray;
  }
}

