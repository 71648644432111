@import 'assets/scss/variables';

.profile-card {
  position: relative;
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  width: 49%;
  height: calc(100% - 30px);
  max-width: 270px;
  border-top-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.12);
  background-color: $color-black-squeeze-light;

  &__close {
    position: absolute;
    left: 0px;
    top: -17px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    background-color: $color-mystic;
    cursor: pointer;
    border: none;
    border-radius: 6px 6px 0 0;

    &::before,
    &::after {
      content: "";
      top: 10px;
      left: 4px;
      position: absolute;
      background-color: $color-persimmon;
      height: 2px;
      width: 12px;
      transform: rotate(-45deg);
    }
    &::after {
      transform: rotate(45deg);
    }
  }

  &__header {
    gap: 19px;
    position: absolute;
    top: -17px;
    right: 0;
    display: flex;
    align-items: center;
    padding: 0 8px;

    &-btn {
      padding: 0;
      color: $color-gray;
    }

    &-rates {
      position: absolute;
      z-index: 100;
      top: calc(100% - 1px);
      right: -30px;
    }
  }

  &__actions,
  &__title,
  &__rating-wrap,
  &__desc,
  &__info-wrap,
  &__location,
  &__services {
    padding: 0 15px;
  }

  &__actions {
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    border-radius: 0px 6px 0px 0px;
    background-color: $color-mystic;
    font-size: 12px;
    line-height: 16px;
    color: $color-gray;
  }
  &__actions-btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 6px;
    background-color: $color-athens-gray;
    color: white;
    cursor: pointer;

    &--prev {
      &::before,
      &::after {
        content: "";
        position: absolute;
        background-color: $color-silver;
        height: 2px;
        width: 10px;
        top: 8px;
        left: 7px;
        transform: rotate(-45deg);
      }
      &::after {
        transform: rotate(45deg);
        top: 14px;
      }
    }
    &--next {
      &::before,
      &::after {
        content: "";
        position: absolute;
        background-color: $color-silver;
        height: 2px;
        width: 10px;
        top: 8px;
        right: 7px;
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
        top: 14px;
      }
    }
  }
  &__actions-btn-wrap {
    gap: 25px;
    display: flex;
    width: 50%;
  }
  &__buffer-btn {
    display: flex;
    padding: 5px;
    color: $color-bondi-blue;

    &--remove {
      color: $color-persimmon;
    }
  }

  &__buttons-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    justify-content: center;
    gap: 4px;
    width: 50%;
  }

  &__btn-sent-icon {
    display: flex;
    color: $color-caribbean-green;
    margin-left: 10px;
  }

  &__btn-send-link {
    height: 24px;
    min-width: 80px;
    border-radius: 6px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.12);
    background-color: $color-bondi-blue;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #ffffff;
    cursor: pointer;

    &--done {
      border: none;
      background-color: inherit;
      cursor: default;
      text-transform: capitalize;
      color: #000000;
      box-shadow: none;
      font-weight: normal;
    }
  }

  &__btn-send-link-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 100%;
    font-size: 14px;
    letter-spacing: 0.88px;
  }

  &__title {
    position: relative;
    padding: 15px 15px 7px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.68px;

    &-rate {
      display: block;
      margin-top: 3px;
    }
  }

  &__tags {
    padding: 0 15px;
  }

  &__prev-booked-count {
    position: absolute;
    top: 0px;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, 0);
    min-width: 40px;
    padding: 2px 5px;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    background-color: #fff;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;

    & > svg {
      margin-left: 4px;
      color: red;
    }
  }
  &__rating-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin: 10px 0;
    padding: 5px 17px 13px 15px;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1px;
    color: #000;
  }

  &__notelist-count {
    display: flex;
    align-items: center;
  }
  &__reviews-btn {
    padding: 0;
    text-decoration: underline;
    font-weight: normal;
    color: $color-azure-radiance;
  }
  &__notelist-btn {
    display: flex;
    margin-left: 6px;
    padding: 0;
    border: none;
    background: no-repeat;
    color: $color-bondi-blue;
    cursor: pointer;

    &-icon {
      width: 20px;
      height: 20px;
    }
  }
  &__labels {
    padding-top: 8px;
    background-color: $color-mystic;
  }
  &__info-wrap {
    padding: 10px 15px;
    font-size: 12px;
  }
  &__info {
    display: flex;
    margin-bottom: 2px;
  }
  &__key {
    width: 50%;
    color: $color-gray;
  }
  &__value {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    color: #000;
    gap: 0 5px;
  }
  &__same-param {
    color: #00a562;
  }
  &__location {
    background-color: $color-mystic;
    display: flex;
    align-items: center;
    padding: 15px 15px 15px 21px;
  }
  &__location-icon {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    min-width: 20px;
    color: $color-bondi-blue;
  }
  &__location-link {
    font-size: 12px;
    line-height: 16px;
    color: $color-gray;
    text-decoration: none;
  }
  &__services {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 10px;
    line-height: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  &__service {
    padding: 2px 10px;
    border-radius: 6px;
    background-color: $color-mystic;
    font-size: 11px;
    line-height: 15px;
    color: $color-tundora-light;

    &--common {
      background-color: rgba($color-spring-green, 0.3);
    }

    &--with-price {
      padding: 2px 0 2px 10px;
    }

    &--price {
      margin-left: 5px;
      padding: 3px 4px;
      border-radius: 0 6px 6px 0;
      background: rgba($color-azure-radiance, 0.3);
      cursor: pointer;
    }
  }
}

@media (max-width: 1450px) {
  .profile-card {
    max-width: 245px;

    &__image-wrap {
      height: 300px;
    }
    &__actions-btn:last-of-type {
      margin-left: 10px;
    }
  }
}
