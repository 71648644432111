@import 'assets/scss/variables.scss';

.contact-form-via-item {
  margin-left: 7px;

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    
    &-checkbox {
      display: block;
      width: 15px;
      height: 15px;
      border: 2px solid $color-gray;
      background-color: $color-white;
    }

    &-title {
      padding-left: 6px;
      &--checked {
        font-weight: bold;
      }
    }
  }

  &__icon {
    &-check {
      width: 15px;
      height: 15px;
      color: $color-caribbean-green;
      background-color: $color-white;
    }

    &-chevron {
      width: 15px;
      height: 15px;
      color: $color-bondi-blue;
      transform: rotate(180deg);
    }
  }

  &__option-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: auto;
    &--email {
      width: 100%;
      text-align: center;
    }
  }
  
  .react-select {
    &__menu {
      right: 0;
      width: 100%;
      min-width: 192px;
      border-radius: 6px;
    }

    &__control {
      min-height: auto;
    }

    &__menu-list {
      border-bottom: 2px solid $color-bondi-blue;
      border-radius: 6px;
    }

    &__dropdown-indicator {
      padding: 0;
    }

    &__option {
      display: flex;
      justify-content: center;
      color: $color-gray;
      &--is-selected {
        color: $color-azure-radiance;
      }
    }
  }
}