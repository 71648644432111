@import '../../../../assets/scss/variables.scss';

.adr-book-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 26px 4px 0 10px;
  background-color: $color-mystic;

  &--centered {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-silver;
    font-size: 14px;
  }

  &__list {
    height: 100%;
    padding: 26px 10px 0;
    background-color: $color-mystic;
  }
}