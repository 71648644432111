@import 'assets/scss/variables';

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar-header-title {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: $color-text-primary-light;
  min-width: 0;
  margin: 0;

  &__icon-wrap{
    display: inline-flex;
    color: $color-text-primary;
  }

  &__text {
    margin: 0 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}