@import 'assets/scss/variables';

.contact-tags {
  $block: &;

  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__tag {
    // display: flex;
    // align-items: center;
    overflow: hidden;
    padding: 2px 6px;
    height: 20px;
    border-radius: 5px;
    background: #f6f6f8;
    text-overflow: ellipsis;
    font-size: 11px;
    line-height: 18px;
    white-space: nowrap;
    color: $color-tundora-light;

    &--hidden-count {
      padding-left: 8px;
      padding-right: 8px;
      background-color: $color-bermuda;
      cursor: pointer;
    }

    &--hidden {
      margin: 0;
      background: darken(#f6f6f8, 2);
      width: min-content;
    }
  }


  &__hidden-tags-wrap {
    box-shadow: 0 12px 12px 0 rgba(#000, 0.12);
    z-index: 1000;

    &[data-popper-placement^="top"] > #{$block}__arrow {
      bottom: -5px;
    }
  }

  &__hidden-tags {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 15px;
    max-width: 350px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 0 0 $color-bondi-blue;
    gap: 5px;

  }

  &__arrow {
    width: 10px;
    height: 10px;

    &:before {
      content: '';
      position: absolute;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      background: inherit;
      background-color: #fff;
      border-style: solid;
      border-width: 2px;
      border-color: $color-bondi-blue;
      border-top-color: #fff;
      border-left-color: #fff;
    }
  }
}