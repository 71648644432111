@import 'assets/scss/variables.scss';

.contact-log-history {
  display: flex;
  flex-grow: 1;
  padding: 25px 5px 16px 16px;

  &__title {
    margin: 10px 0px;
    font-size: 24px;
  }
  &__list-wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  &__list-container {
    flex-grow: 1;
  }
  &__list {
    padding-right: 11px;
  }
  &__no-items {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: $color-silver;
    font-size: 14px;
  }
  &__load-wrap {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
  }
}

.contact-log {
  display: block;
  position: relative;
  height: 38px;
  margin-bottom: 2px;
  padding: 2px 10px;
  border-radius: 5px;
  background: $color-athens-gray;
  transition: max-height 0.2s ease-in;

  &__row {
    display: flex;
    align-items: center;
  }

  &__date {
    display: flex;
    align-items: center;
    padding-right: 4px;
    color: $color-bali-hai;
    font-size: 12px;
    line-height: 17px;
  }

  &__operator {
    max-width: 40%;
    color: $color-azure-radiance;
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 1px;
  }

  &__action {
    margin-left: 15px;
    font-size: 11px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__undo-btn {
    position: absolute;
    top: 12px;
    right: 10px;
    color: $color-bondi-blue;
    cursor: pointer;
  }

  &__update-list {
    &-item {
      word-break: break-word;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__description {
    display: flex;
    z-index: 9;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 340px;
    height: fit-content;
    min-height: 40px;
    margin: auto;
    padding: 20px 15px;
    border-radius: 6px;
    overflow: hidden;
    color: $color-black;
    font-weight: 300;
    font-size: 12px;
    text-align: center;
    letter-spacing: 1px;
    background-color: $color-white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

    &-avatar {
      display: inline-block;
      justify-content: center;
      margin: 0 4px;
      width: 30px;
      height: 30px;
      overflow: hidden;
    }
  }
}